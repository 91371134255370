import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import * as recipeActions from "../actions/recipe";
import RecipeComponent from "../components/Recipe";
import React from "react";

const RecipeDataPrepare = ({ recipes, recipeTags, history }) => {
  const { id } = useParams();

  const recipe = recipes.find((r) => r.id === id);

  if (recipe !== undefined) {
    return (
      <RecipeComponent
        recipe={recipe}
        history={history}
        recipeTags={recipeTags}
      />
    );
  } else {
    return <>Recette inconnue</>;
  }
};

const Recipe = withRouter(
  connect((state, ownProps) => {
    return {
      recipes: state.recipes,
      recipeTags: state.recipeTags,
    };
  }, recipeActions)(RecipeDataPrepare)
);

export default Recipe;

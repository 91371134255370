import React, { useEffect } from "react";
import { AutoComplete, Button, Form, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
};

const cleanValues = (ingredient) => {
  return {
    name: ingredient.name,
  };
};

const normalizedName = (name) => {
  const clean = name.trim().replace(/\s+/g, " ").toLowerCase();
  return (
    clean.charAt(0).toUpperCase() +
    clean.slice(1) +
    (name.endsWith(" ") ? " " : "")
  );
};

const AddIngredient = ({
  ingredientsTags,
  ingredients,
  addIngredientsTags,
  addIngredient,
  onSuccess = () => {},
  name,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (name !== undefined) {
      form.setFieldsValue({ name: normalizedName(name) });
    }
  }, [name, form]);

  const onFinish = (values) => {
    addIngredient(cleanValues(values));
    addIngredientsTags(ingredientsTags, values.tags);
    form.resetFields();
    message.success("L'ingrédient \"" + values.name + '" a été ajouté');
    onSuccess(values.name);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onNameChange = (value) => {
    form.setFieldsValue({ name: normalizedName(value) });
  };

  const nameIsValid = (name) => {
    const existing = ingredients.reduce((acc, i) => {
      acc.push(
        i.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
      return acc;
    }, []);
    return (
      existing.indexOf(
        name
          .trim()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      ) < 0
    );
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="add-ingredient"
        size="large"
        initialValues={{
          remember: true,
          name: name,
          // availability: [1, 12],
          img: "default.svg",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Nom"
          name="name"
          rules={[
            { required: true, message: "Le nom est requis!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value === undefined || nameIsValid(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("L'ingrédient existe déja!");
              },
            }),
          ]}
        >
          <AutoComplete
            options={ingredients.map((i) => ({ value: i.name }))}
            filterOption={(inputValue, option) =>
              option.value
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
                .indexOf(
                  inputValue
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                ) !== -1
            }
            onChange={onNameChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            <PlusCircleOutlined />
            Ajouter
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddIngredient;

/*
        <Form.Item
          label="Image"
          name="img"
          rules={[{ required: true, message: "Choisissez une image!" }]}
        >
          <Select placeholder="Choisir une image" allowClear>
            {Object.keys(ingredientImages).map((key) => {
              return (
                <Option key={key} value={key}>
                  <Tooltip title={key}>
                    <Avatar
                      shape="square"
                      size="default"
                      src={ingredientImages[key]}
                    >
                      {" "}
                    </Avatar>
                  </Tooltip>
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="availability"
          label={
            <span>
              Disponibilité&nbsp;
              <Tooltip title="Saison de l'ingrédient">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Slider
            range
            marks={{
              1: "J",
              2: "F",
              3: "M",
              4: "A",
              5: "M",
              6: "J",
              7: "J",
              8: "A",
              9: "S",
              10: "O",
              11: "N",
              12: "D",
            }}
            tipFormatter={null}
            step={1}
            min={1}
            max={12}
          />
        </Form.Item>

        <Form.Item label="Catégorie" name="tags">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            tagRender={tagRender}
            placeholder="Fruit, Légume ..."
          >
            {Object.keys(ingredientsTags).map((tId) => (
              <Option key={ingredientsTags[tId].name}>
                {ingredientsTags[tId].name}
              </Option>
            ))}
          </Select>
        </Form.Item>
*/

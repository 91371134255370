import Icon from "@ant-design/icons";
import React from "react";
import { Space, Switch, Tooltip } from "antd";

const { useState } = require("react");

const autumnSVG = () => (
  <svg
    height="1em"
    viewBox="0 0 512 512"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m272.851 254.973 18.662 48.155c.687 1.773-1.017 3.538-2.814 2.913l-104.355-36.328c-.475-.165-.992-.165-1.467 0l-104.353 36.328c-1.796.625-3.501-1.14-2.814-2.913l18.662-48.155c.503-1.297-.292-2.734-1.658-2.997l-90.903-17.501c-1.689-.325-2.39-2.358-1.261-3.655l43.923-50.48c.477-.548.657-1.294.483-1.999l-16.062-65.094c-.426-1.726 1.24-3.233 2.915-2.636l83.129 29.648c1.662.593 3.321-.887 2.922-2.605l-26.195-112.909c-.384-1.656 1.151-3.116 2.786-2.649l46.584 13.304c.731.209 1.517.031 2.087-.471l39.015-34.369c.843-.743 2.106-.743 2.949 0l39.015 34.369c.57.502 1.357.68 2.087.471l46.584-13.304c1.635-.467 3.17.993 2.786 2.649l-26.196 112.91c-.399 1.719 1.261 3.198 2.923 2.605l83.129-29.648c1.675-.597 3.341.909 2.915 2.636l-16.062 65.094c-.174.705.006 1.451.483 1.999l43.923 50.48c1.129 1.297.427 3.33-1.261 3.655l-90.903 17.501c-1.366.262-2.161 1.699-1.658 2.996z"
        fill="#fea95c"
      />
      <g fill="#fe9738">
        <path d="m218.457 137.655c-.399 1.719 1.261 3.198 2.922 2.605l29.848-10.645 24.331-104.87c.384-1.656-1.151-3.116-2.786-2.649l-29.457 8.413z" />
        <path d="m193.196 34.929c.57.502 1.357.68 2.087.471l22.167-6.331-32.363-28.51c-.843-.743-2.106-.743-2.949 0l-13.978 12.314z" />
        <path d="m366.674 230.82-43.923-50.48c-.477-.548-.657-1.294-.483-1.999l16.061-65.094c.426-1.726-1.24-3.233-2.915-2.636l-29.978 10.692-14.074 57.038c-.174.705.006 1.451.483 1.999l43.923 50.48c1.129 1.297.427 3.33-1.261 3.655l-90.903 17.501c-1.366.263-2.161 1.699-1.658 2.997l15.586 40.218 31.168 10.85c1.796.625 3.501-1.14 2.814-2.913l-18.662-48.155c-.503-1.298.292-2.734 1.658-2.997l90.903-17.501c1.688-.325 2.389-2.358 1.261-3.655z" />
      </g>
      <path
        d="m427.282 435.292 16.753 43.229c.617 1.592-.913 3.176-2.526 2.615l-93.679-32.612c-.426-.148-.89-.148-1.317 0l-93.679 32.612c-1.612.561-3.143-1.023-2.526-2.615l16.753-43.229c.451-1.165-.262-2.454-1.489-2.69l-81.604-15.711c-1.516-.292-2.145-2.117-1.132-3.281l39.43-45.316c.428-.492.59-1.161.434-1.794l-14.419-58.435c-.382-1.55 1.113-2.902 2.617-2.366l74.625 26.615c1.492.532 2.981-.796 2.624-2.339l-23.516-101.36c-.345-1.487 1.033-2.797 2.501-2.378l41.819 11.943c.656.187 1.362.028 1.874-.423l35.024-30.853c.757-.667 1.891-.667 2.648 0l35.024 30.853c.512.451 1.218.61 1.874.423l41.819-11.943c1.467-.419 2.846.892 2.501 2.378l-23.516 101.36c-.358 1.543 1.132 2.871 2.624 2.339l74.625-26.615c1.504-.536 2.999.816 2.617 2.366l-14.422 58.435c-.156.633.006 1.302.434 1.794l39.43 45.316c1.013 1.164.384 2.989-1.132 3.281l-81.604 15.711c-1.227.236-1.941 1.526-1.489 2.69z"
        fill="#fe646f"
      />
      <g fill="#fd4755">
        <path d="m378.452 329.976c-.358 1.543 1.132 2.871 2.624 2.339l26.795-9.556 21.842-94.142c.345-1.487-1.033-2.797-2.501-2.378l-26.444 7.552z" />
        <path d="m355.774 237.758c.512.451 1.218.61 1.874.423l19.899-5.683-29.052-25.593c-.757-.667-1.891-.667-2.648 0l-12.548 11.054z" />
        <path d="m511.506 413.61-39.43-45.316c-.428-.492-.59-1.161-.434-1.794l14.418-58.435c.382-1.55-1.113-2.902-2.617-2.366l-26.911 9.598-12.633 51.203c-.156.633.006 1.302.434 1.794l39.43 45.316c1.013 1.165.384 2.989-1.132 3.281l-81.604 15.711c-1.227.236-1.94 1.525-1.489 2.69l13.992 36.104 27.98 9.74c1.612.561 3.143-1.023 2.526-2.615l-16.753-43.229c-.451-1.165.262-2.454 1.489-2.69l81.604-15.711c1.514-.292 2.144-2.116 1.13-3.281z" />
      </g>
      <path
        d="m288.98 192.453-97.87 28.83v48.31c0 .82-.13 1.6-.38 2.34-.17.52-.4 1.02-.68 1.49-1.87 11.09-4.96 40.2 11.15 52.72 3.27 2.55 3.85 7.26 1.31 10.53-1.48 1.9-3.69 2.89-5.92 2.89-1.61 0-3.23-.51-4.6-1.58-21.77-16.93-19.29-50.84-17-65.52.44-2.8.87-4.9 1.12-6.05v-45l-99.49-28.96c-3.97-1.15-6.26-5.32-5.1-9.29 1.16-3.98 5.32-6.26 9.3-5.11l95.29 27.74v-85.75l-50.16-35.4c-3.38-2.39-4.19-7.07-1.8-10.45 2.39-3.39 7.07-4.19 10.45-1.81l41.51 29.3v-95.811l6.03-5.31c.84-.75 2.1-.75 2.95 0l6.02 5.3v95.56l39.78-28.97c3.35-2.44 8.04-1.7 10.48 1.65 2.44 3.34 1.7 8.04-1.65 10.47l-48.61 35.4v85.67l93.63-27.58c3.98-1.17 8.15 1.1 9.32 5.07 1.17 3.98-1.1 8.15-5.08 9.321z"
        fill="#fe860a"
      />
      <path
        d="m441.98 379.9-87.31 25.72v40.81c-.01.14-.02.29-.03.43 0 .12-.01.24-.03.35-.01.11-.03.23-.05.34-.02.14-.05.29-.08.44-.01.03-.01.06-.02.09-.18.76-.37 1.61-.55 2.56-2.27 11.84-3.98 37.44 9.52 47.94 3.27 2.54 3.86 7.25 1.31 10.52-1.48 1.91-3.69 2.9-5.92 2.9-1.61 0-3.23-.52-4.6-1.58-19.66-15.29-17.66-45.63-15.6-59.15.4-2.67.81-4.68 1.05-5.79v-39.74l-88.76-25.83c-3.97-1.16-6.26-5.32-5.1-9.3 1.15-3.98 5.32-6.26 9.29-5.11l84.57 24.62v-75.55l-44.7-31.56c-3.39-2.39-4.19-7.07-1.81-10.45 2.39-3.39 7.07-4.19 10.46-1.8l36.05 25.45v-83.87l6.18-5.44c.75-.66 1.89-.66 2.64 0l6.18 5.44v83.6l34.49-25.12c3.35-2.44 8.04-1.7 10.48 1.65s1.7 8.04-1.65 10.48l-43.32 31.55v75.48l83.07-24.47c3.98-1.17 8.14 1.1 9.32 5.08 1.17 3.97-1.11 8.14-5.08 9.31z"
        fill="#fb2b3a"
      />
    </g>
  </svg>
);

const winterSVG = () => (
  <svg
    height="1em"
    viewBox="0 0 512 512"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g fill="#92d1f7">
        <path d="m256 128a7.994 7.994 0 0 1 -4.116-1.14l-40-24a8 8 0 0 1 8.232-13.72l35.884 21.531 35.884-21.531a8 8 0 0 1 8.232 13.72l-40 24a7.994 7.994 0 0 1 -4.116 1.14z" />
        <path d="m256.006 96a8 8 0 0 1 -3.584-15.157l32-16a8 8 0 1 1 7.156 14.31l-32 16a7.976 7.976 0 0 1 -3.572.847z" />
        <path d="m255.994 96a7.976 7.976 0 0 1 -3.572-.847l-32-16a8 8 0 0 1 7.156-14.31l32 16a8 8 0 0 1 -3.584 15.157z" />
        <path d="m256 64a7.985 7.985 0 0 1 -3.578-.845l-16-8a8 8 0 0 1 7.156-14.31l12.422 6.211 12.422-6.211a8 8 0 1 1 7.156 14.31l-16 8a7.985 7.985 0 0 1 -3.578.845z" />
        <path d="m256 374.852a7.993 7.993 0 0 1 -4-1.073l-96-55.425a8 8 0 0 1 -4-6.928v-110.852a8 8 0 0 1 4-6.928l96-55.425a8 8 0 0 1 8 0l96 55.425a8 8 0 0 1 4 6.928v110.852a8 8 0 0 1 -4 6.928l-96 55.425a7.993 7.993 0 0 1 -4 1.073zm-88-68.045 88 50.807 88-50.807v-101.614l-88-50.807-88 50.807z" />
        <path d="m256 280a7.992 7.992 0 0 1 -4-1.072l-13.856-8a8 8 0 0 1 -4-6.928v-16a8 8 0 0 1 4-6.928l13.856-8a8 8 0 0 1 8 0l13.856 8a8 8 0 0 1 4 6.928v16a8 8 0 0 1 -4 6.928l-13.856 8a7.992 7.992 0 0 1 -4 1.072zm-5.856-20.619 5.856 3.382 5.856-3.382v-6.762l-5.856-3.382-5.856 3.382z" />
        <path d="m256 160a7.992 7.992 0 0 1 -4.438-1.344l-24-16a8 8 0 1 1 8.876-13.312l19.562 13.041 19.562-13.041a8 8 0 0 1 8.876 13.312l-24 16a7.992 7.992 0 0 1 -4.438 1.344z" />
      </g>
      <path
        d="m256 216a8 8 0 0 1 -8-8v-184a8 8 0 0 1 16 0v184a8 8 0 0 1 -8 8z"
        fill="#5ebef7"
      />
      <path
        d="m97.445 218.641a8 8 0 0 1 -3.89-15l36.588-20.312-.705-41.841a8 8 0 0 1 16-.269l.784 46.64a8 8 0 0 1 -4.116 7.129l-40.786 22.646a7.972 7.972 0 0 1 -3.875 1.007z"
        fill="#92d1f7"
      />
      <path
        d="m110.517 180c-.162 0-.324 0-.487-.015a8 8 0 0 1 -7.507-8.464l2.144-35.713a8 8 0 0 1 15.972.959l-2.139 35.712a8 8 0 0 1 -7.983 7.521z"
        fill="#92d1f7"
      />
      <path
        d="m80.66 199.713a8 8 0 0 1 -4.415-14.677l29.855-19.713a8 8 0 1 1 8.815 13.352l-29.854 19.713a7.965 7.965 0 0 1 -4.401 1.325z"
        fill="#92d1f7"
      />
      <path
        d="m67.876 173.856a8 8 0 0 1 -4.416-14.676l11.59-7.653.833-13.864a8 8 0 0 1 15.971.959l-1.072 17.857a8 8 0 0 1 -3.577 6.2l-14.928 9.856a7.96 7.96 0 0 1 -4.401 1.321z"
        fill="#92d1f7"
      />
      <path
        d="m140.084 224.785a8 8 0 0 1 -3.551-15.173l21.075-10.421-1.508-23.462a8 8 0 1 1 15.967-1.029l1.856 28.784a8 8 0 0 1 -4.437 7.686l-25.856 12.784a7.967 7.967 0 0 1 -3.546.831z"
        fill="#92d1f7"
      />
      <path
        d="m214.425 240a7.963 7.963 0 0 1 -3.992-1.073l-159.349-92a8 8 0 1 1 8-13.856l159.349 92a8 8 0 0 1 -4.008 14.929z"
        fill="#5ebef7"
      />
      <path
        d="m137.44 378.638h-.14a8 8 0 0 1 -7.865-8.134l.705-41.841-36.585-20.312a8 8 0 1 1 7.765-13.989l40.78 22.638a8 8 0 0 1 4.116 7.129l-.784 46.64a8 8 0 0 1 -7.992 7.869z"
        fill="#92d1f7"
      />
      <path
        d="m110.5 348a7.96 7.96 0 0 1 -4.4-1.325l-29.855-19.715a8 8 0 0 1 8.816-13.352l29.856 19.713a8 8 0 0 1 -4.417 14.679z"
        fill="#92d1f7"
      />
      <path
        d="m112.646 383.71a8 8 0 0 1 -7.979-7.521l-2.144-35.712a8 8 0 0 1 15.972-.959l2.144 35.712a8 8 0 0 1 -7.507 8.465q-.245.015-.486.015z"
        fill="#92d1f7"
      />
      <path
        d="m83.861 381.854a8 8 0 0 1 -7.978-7.521l-.833-13.864-11.59-7.653a8 8 0 1 1 8.817-13.351l14.928 9.856a8 8 0 0 1 3.577 6.2l1.072 17.856a8 8 0 0 1 -7.506 8.465c-.163.007-.326.012-.487.012z"
        fill="#92d1f7"
      />
      <path
        d="m164.086 344.781c-.173 0-.347 0-.523-.016a8 8 0 0 1 -7.468-8.5l1.513-23.462-21.075-10.421a8 8 0 1 1 7.092-14.342l25.856 12.784a8 8 0 0 1 4.437 7.686l-1.856 28.79a8 8 0 0 1 -7.976 7.481z"
        fill="#92d1f7"
      />
      <path
        d="m55.091 380a8 8 0 0 1 -4.007-14.929l159.349-92a8 8 0 0 1 8 13.856l-159.349 92a7.967 7.967 0 0 1 -3.993 1.073z"
        fill="#5ebef7"
      />
      <path
        d="m295.992 424a7.964 7.964 0 0 1 -4.108-1.141l-35.884-21.534-35.884 21.531a8 8 0 0 1 -8.232-13.72l40-24a8 8 0 0 1 8.232 0l40 24a8 8 0 0 1 -4.124 14.864z"
        fill="#92d1f7"
      />
      <path
        d="m224.006 448a8 8 0 0 1 -3.584-15.157l32-16a8 8 0 1 1 7.156 14.31l-32 16a7.963 7.963 0 0 1 -3.572.847z"
        fill="#92d1f7"
      />
      <path
        d="m287.994 448a7.963 7.963 0 0 1 -3.572-.847l-32-16a8 8 0 1 1 7.156-14.31l32 16a8 8 0 0 1 -3.584 15.157z"
        fill="#92d1f7"
      />
      <path
        d="m271.994 472a7.963 7.963 0 0 1 -3.572-.847l-12.422-6.213-12.422 6.211a8 8 0 0 1 -7.156-14.31l16-8a8 8 0 0 1 7.156 0l16 8a8 8 0 0 1 -3.584 15.159z"
        fill="#92d1f7"
      />
      <path
        d="m279.993 384a7.96 7.96 0 0 1 -4.431-1.345l-19.562-13.044-19.562 13.041a8 8 0 0 1 -8.876-13.312l24-16a8 8 0 0 1 8.876 0l24 16a8 8 0 0 1 -4.445 14.66z"
        fill="#92d1f7"
      />
      <path
        d="m256 496a8 8 0 0 1 -8-8v-184a8 8 0 0 1 16 0v184a8 8 0 0 1 -8 8z"
        fill="#5ebef7"
      />
      <path
        d="m374.56 378.638a8 8 0 0 1 -8-7.866l-.784-46.64a8 8 0 0 1 4.124-7.132l40.785-22.641a8 8 0 0 1 7.765 13.989l-36.588 20.311.7 41.841a8 8 0 0 1 -7.865 8.134z"
        fill="#92d1f7"
      />
      <path
        d="m399.354 383.71q-.242 0-.486-.015a8 8 0 0 1 -7.507-8.465l2.144-35.712a8 8 0 0 1 15.972.959l-2.144 35.712a8 8 0 0 1 -7.979 7.521z"
        fill="#92d1f7"
      />
      <path
        d="m401.5 348a8 8 0 0 1 -4.415-14.677l29.856-19.713a8 8 0 1 1 8.816 13.352l-29.857 19.711a7.96 7.96 0 0 1 -4.4 1.327z"
        fill="#92d1f7"
      />
      <path
        d="m428.139 381.854c-.162 0-.324 0-.487-.015a8 8 0 0 1 -7.506-8.465l1.072-17.856a8 8 0 0 1 3.577-6.2l14.928-9.856a8 8 0 0 1 8.817 13.351l-11.59 7.653-.833 13.864a8 8 0 0 1 -7.978 7.524z"
        fill="#92d1f7"
      />
      <path
        d="m347.914 344.781a8 8 0 0 1 -7.976-7.485l-1.856-28.784a8 8 0 0 1 4.437-7.686l25.856-12.784a8 8 0 1 1 7.092 14.342l-21.075 10.421 1.513 23.462a8 8 0 0 1 -7.468 8.5c-.176.009-.35.014-.523.014z"
        fill="#92d1f7"
      />
      <path
        d="m456.909 380a7.967 7.967 0 0 1 -3.993-1.073l-159.349-92a8 8 0 0 1 8-13.856l159.349 92a8 8 0 0 1 -4.007 14.929z"
        fill="#5ebef7"
      />
      <path
        d="m414.555 218.641a7.95 7.95 0 0 1 -3.875-1.007l-40.78-22.641a8 8 0 0 1 -4.116-7.129l.784-46.64a8 8 0 0 1 16 .269l-.7 41.841 36.588 20.312a8 8 0 0 1 -3.89 15z"
        fill="#92d1f7"
      />
      <path
        d="m431.34 199.713a7.965 7.965 0 0 1 -4.4-1.325l-29.856-19.713a8 8 0 1 1 8.815-13.352l29.857 19.713a8 8 0 0 1 -4.415 14.677z"
        fill="#92d1f7"
      />
      <path
        d="m401.483 180a8 8 0 0 1 -7.978-7.52l-2.144-35.713a8 8 0 0 1 15.972-.959l2.144 35.713a8 8 0 0 1 -7.507 8.464c-.163.009-.325.015-.487.015z"
        fill="#92d1f7"
      />
      <path
        d="m444.124 173.856a7.957 7.957 0 0 1 -4.4-1.325l-14.924-9.856a8 8 0 0 1 -3.577-6.2l-1.072-17.857a8 8 0 0 1 15.971-.959l.833 13.864 11.59 7.653a8 8 0 0 1 -4.416 14.676z"
        fill="#92d1f7"
      />
      <path
        d="m371.916 224.785a7.957 7.957 0 0 1 -3.541-.831l-25.856-12.784a8 8 0 0 1 -4.437-7.686l1.856-28.784a8 8 0 1 1 15.967 1.029l-1.513 23.462 21.075 10.421a8 8 0 0 1 -3.551 15.173z"
        fill="#92d1f7"
      />
      <path
        d="m297.575 240a8 8 0 0 1 -4.008-14.929l159.349-92a8 8 0 1 1 8 13.856l-159.349 92a7.963 7.963 0 0 1 -3.992 1.073z"
        fill="#5ebef7"
      />
      <path
        d="m304 347.139a8 8 0 0 1 -4.726-1.545l-43.274-31.68-43.274 31.68a8 8 0 0 1 -12.679-7.321l5.8-53.316-49.075-21.637a8 8 0 0 1 0-14.64l49.072-21.637-5.8-53.316a8 8 0 0 1 12.679-7.321l43.277 31.68 43.274-31.68a8 8 0 0 1 12.679 7.321l-5.8 53.316 49.072 21.637a8 8 0 0 1 0 14.64l-49.072 21.637 5.8 53.316a8 8 0 0 1 -7.953 8.866zm-48-51.139a7.991 7.991 0 0 1 4.726 1.545l33.359 24.421-4.469-41.1a8 8 0 0 1 4.726-8.185l37.829-16.681-37.829-16.68a8 8 0 0 1 -4.726-8.185l4.469-41.1-33.359 24.421a8 8 0 0 1 -9.452 0l-33.359-24.421 4.469 41.1a8 8 0 0 1 -4.726 8.185l-37.829 16.68 37.829 16.68a8 8 0 0 1 4.726 8.185l-4.469 41.1 33.359-24.421a7.991 7.991 0 0 1 4.726-1.544z"
        fill="#5ebef7"
      />
    </g>
  </svg>
);

const summerSVG = () => (
  <svg
    height="1em"
    viewBox="0 0 512 512"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle fill="#FFC107" cx="245.333" cy="245.333" r="160" />
    <g>
      <path
        fill="#FFD54F"
        d="M245.333,64C251.224,64,256,59.224,256,53.333V10.667C256,4.776,251.224,0,245.333,0
c-5.891,0-10.667,4.776-10.667,10.667v42.667C234.667,59.224,239.442,64,245.333,64z"
      />
      <path
        fill="#FFD54F"
        d="M245.333,426.667c-5.891,0-10.667,4.776-10.667,10.667V480c0,5.891,4.776,10.667,10.667,10.667
c5.891,0,10.667-4.776,10.667-10.667v-42.667C256,431.442,251.224,426.667,245.333,426.667z"
      />
      <path
        fill="#FFD54F"
        d="M480,234.667h-42.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667H480
c5.891,0,10.667-4.776,10.667-10.667C490.667,239.442,485.891,234.667,480,234.667z"
      />
      <path
        fill="#FFD54F"
        d="M64,245.333c0-5.891-4.776-10.667-10.667-10.667H10.667C4.776,234.667,0,239.442,0,245.333
C0,251.224,4.776,256,10.667,256h42.667C59.224,256,64,251.224,64,245.333z"
      />
      <path
        fill="#FFD54F"
        d="M140.096,84.395c1.909,3.307,5.44,5.341,9.259,5.333c1.873,0.007,3.715-0.486,5.333-1.429
c5.102-2.946,6.849-9.469,3.904-14.571l-21.333-36.949c-2.979-5.082-9.514-6.787-14.596-3.808
c-5.035,2.951-6.763,9.401-3.878,14.474L140.096,84.395z"
      />
      <path
        fill="#FFD54F"
        d="M350.571,406.272c-2.98-5.082-9.515-6.786-14.597-3.806c-5.033,2.952-6.761,9.4-3.878,14.473
l21.333,36.949c1.909,3.307,5.44,5.341,9.259,5.333c1.873,0.007,3.715-0.486,5.333-1.429c5.102-2.946,6.849-9.469,3.904-14.571
L350.571,406.272z"
      />
      <path
        fill="#FFD54F"
        d="M411.605,160c1.873,0.007,3.715-0.486,5.333-1.429l36.949-21.333
c5.22-2.731,7.238-9.176,4.507-14.396c-2.731-5.22-9.176-7.238-14.396-4.507c-0.266,0.139-0.525,0.289-0.778,0.45l-36.949,21.333
c-5.102,2.946-6.849,9.47-3.904,14.571c1.905,3.3,5.426,5.333,9.237,5.333V160z"
      />
      <path
        fill="#FFD54F"
        d="M73.728,332.096l-36.949,21.333c-5.102,2.946-6.849,9.469-3.904,14.571
c1.905,3.3,5.426,5.333,9.237,5.333c1.873,0.007,3.715-0.486,5.333-1.429l36.949-21.333c5.22-2.731,7.238-9.176,4.507-14.396
c-2.731-5.22-9.176-7.238-14.396-4.507c-0.266,0.139-0.525,0.289-0.778,0.45L73.728,332.096z"
      />
      <path
        fill="#FFD54F"
        d="M36.779,137.237l36.949,21.333c1.613,0.939,3.446,1.433,5.312,1.429
c5.891,0,10.666-4.776,10.666-10.667c0-3.81-2.033-7.331-5.333-9.237l-36.949-21.333c-4.972-3.16-11.564-1.692-14.724,3.279
s-1.692,11.564,3.279,14.724c0.253,0.161,0.512,0.311,0.778,0.45L36.779,137.237z"
      />
      <path
        fill="#FFD54F"
        d="M453.888,353.429l-36.949-21.333c-4.972-3.16-11.564-1.692-14.724,3.279
c-3.16,4.971-1.692,11.564,3.279,14.724c0.253,0.161,0.513,0.311,0.778,0.45l36.949,21.333c1.619,0.943,3.46,1.436,5.333,1.429
c5.891,0,10.666-4.776,10.666-10.667c0-3.811-2.033-7.332-5.333-9.237V353.429z"
      />
      <path
        fill="#FFD54F"
        d="M336,88.299c1.619,0.943,3.46,1.436,5.333,1.429c3.818,0.008,7.349-2.027,9.259-5.333l21.333-36.949
c2.911-5.121,1.119-11.633-4.002-14.544c-5.073-2.883-11.521-1.156-14.473,3.878l-21.333,36.949
C329.168,78.824,330.906,85.346,336,88.299z"
      />
      <path
        fill="#FFD54F"
        d="M154.667,402.368c-5.102-2.945-11.625-1.198-14.571,3.904l-21.333,36.949
c-2.945,5.102-1.198,11.625,3.904,14.571c1.619,0.943,3.46,1.436,5.333,1.429c3.818,0.008,7.35-2.027,9.259-5.333l21.333-36.949
c2.94-5.105,1.186-11.627-3.919-14.567C154.671,402.37,154.669,402.369,154.667,402.368z"
      />
    </g>
  </svg>
);

const summerSVGGS = () => (
  <svg
    height="1em"
    viewBox="0 0 512 512"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle fill="#BFBFBF" cx="245.333" cy="245.333" r="160" />
    <g>
      <path
        fill="#D3D3D3"
        d="M245.333,64C251.224,64,256,59.224,256,53.333V10.667C256,4.776,251.224,0,245.333,0
c-5.891,0-10.667,4.776-10.667,10.667v42.667C234.667,59.224,239.442,64,245.333,64z"
      />
      <path
        fill="#D3D3D3"
        d="M245.333,426.667c-5.891,0-10.667,4.776-10.667,10.667V480c0,5.891,4.776,10.667,10.667,10.667
c5.891,0,10.667-4.776,10.667-10.667v-42.667C256,431.442,251.224,426.667,245.333,426.667z"
      />
      <path
        fill="#D3D3D3"
        d="M480,234.667h-42.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667H480
c5.891,0,10.667-4.776,10.667-10.667C490.667,239.442,485.891,234.667,480,234.667z"
      />
      <path
        fill="#D3D3D3"
        d="M64,245.333c0-5.891-4.776-10.667-10.667-10.667H10.667C4.776,234.667,0,239.442,0,245.333
C0,251.224,4.776,256,10.667,256h42.667C59.224,256,64,251.224,64,245.333z"
      />
      <path
        fill="#D3D3D3"
        d="M140.096,84.395c1.909,3.307,5.44,5.341,9.259,5.333c1.873,0.007,3.715-0.486,5.333-1.429
c5.102-2.946,6.849-9.469,3.904-14.571l-21.333-36.949c-2.979-5.082-9.514-6.787-14.596-3.808
c-5.035,2.951-6.763,9.401-3.878,14.474L140.096,84.395z"
      />
      <path
        fill="#D3D3D3"
        d="M350.571,406.272c-2.98-5.082-9.515-6.786-14.597-3.806c-5.033,2.952-6.761,9.4-3.878,14.473
l21.333,36.949c1.909,3.307,5.44,5.341,9.259,5.333c1.873,0.007,3.715-0.486,5.333-1.429c5.102-2.946,6.849-9.469,3.904-14.571
L350.571,406.272z"
      />
      <path
        fill="#D3D3D3"
        d="M411.605,160c1.873,0.007,3.715-0.486,5.333-1.429l36.949-21.333
c5.22-2.731,7.238-9.176,4.507-14.396c-2.731-5.22-9.176-7.238-14.396-4.507c-0.266,0.139-0.525,0.289-0.778,0.45l-36.949,21.333
c-5.102,2.946-6.849,9.47-3.904,14.571c1.905,3.3,5.426,5.333,9.237,5.333V160z"
      />
      <path
        fill="#D3D3D3"
        d="M73.728,332.096l-36.949,21.333c-5.102,2.946-6.849,9.469-3.904,14.571
c1.905,3.3,5.426,5.333,9.237,5.333c1.873,0.007,3.715-0.486,5.333-1.429l36.949-21.333c5.22-2.731,7.238-9.176,4.507-14.396
c-2.731-5.22-9.176-7.238-14.396-4.507c-0.266,0.139-0.525,0.289-0.778,0.45L73.728,332.096z"
      />
      <path
        fill="#D3D3D3"
        d="M36.779,137.237l36.949,21.333c1.613,0.939,3.446,1.433,5.312,1.429
c5.891,0,10.666-4.776,10.666-10.667c0-3.81-2.033-7.331-5.333-9.237l-36.949-21.333c-4.972-3.16-11.564-1.692-14.724,3.279
s-1.692,11.564,3.279,14.724c0.253,0.161,0.512,0.311,0.778,0.45L36.779,137.237z"
      />
      <path
        fill="#D3D3D3"
        d="M453.888,353.429l-36.949-21.333c-4.972-3.16-11.564-1.692-14.724,3.279
c-3.16,4.971-1.692,11.564,3.279,14.724c0.253,0.161,0.513,0.311,0.778,0.45l36.949,21.333c1.619,0.943,3.46,1.436,5.333,1.429
c5.891,0,10.666-4.776,10.666-10.667c0-3.811-2.033-7.332-5.333-9.237V353.429z"
      />
      <path
        fill="#D3D3D3"
        d="M336,88.299c1.619,0.943,3.46,1.436,5.333,1.429c3.818,0.008,7.349-2.027,9.259-5.333l21.333-36.949
c2.911-5.121,1.119-11.633-4.002-14.544c-5.073-2.883-11.521-1.156-14.473,3.878l-21.333,36.949
C329.168,78.824,330.906,85.346,336,88.299z"
      />
      <path
        fill="#D3D3D3"
        d="M154.667,402.368c-5.102-2.945-11.625-1.198-14.571,3.904l-21.333,36.949
c-2.945,5.102-1.198,11.625,3.904,14.571c1.619,0.943,3.46,1.436,5.333,1.429c3.818,0.008,7.35-2.027,9.259-5.333l21.333-36.949
c2.94-5.105,1.186-11.627-3.919-14.567C154.671,402.37,154.669,402.369,154.667,402.368z"
      />
    </g>
  </svg>
);

const springSVG = () => (
  <svg
    height="1em"
    viewBox="0 0 512 512"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m481.094 89.996h-60v30h60c8.401 0 15-6.601 15-15 0-8.401-6.599-15-15-15z"
        fill="#e63950"
      />
      <path
        d="m421.094 89.996h-5.4c-6.899 0-11.4-5.7-12.9-11.4-12.601-48.001-57.601-80.8-107.401-78.4-3.182.174-6.191 1.018-9.3 1.5-50.218 7.776-90 53.875-90 108.4 0 14.101-4.2 27.599-12.001 39l-79.799 176.4c-18.3 33.3-28.2 71.1-28.2 109.501v62c0 8.399 6.599 15 15 15s15-6.601 15-15c0-24.901 20.099-47 45-47h30c28.757 0 55.807-7.452 79.385-20.469l25.615-73.013 61.348-174.866c6.321-35.015 36.824-61.652 73.652-61.652 8.401 0 15-6.601 15-15 .001-8.402-6.598-15.001-14.999-15.001z"
        fill="#59c36a"
      />
      <path
        d="m421.094 119.996c8.401 0 15-6.601 15-15 0-8.401-6.599-15-15-15h-5.4c-6.899 0-11.4-5.7-12.9-11.4-12.601-48.001-57.601-80.8-107.401-78.4-3.182.174-6.191 1.018-9.3 1.5v354.818l61.348-174.866c6.321-35.016 36.825-61.652 73.653-61.652z"
        fill="#00a66c"
      />
      <path
        d="m331.094 179.996c-15.855 0-30.855 3.25-45 8.293-52.266 18.636-90 68.119-90 126.707 0 48.481 25.911 90.721 64.385 114.531 9.179-5.067 17.602-11.245 25.615-17.9 36.476-30.295 60-75.679 60-126.63v-90c0-4.572.564-9.001 1.348-13.348-5.422-.666-10.751-1.653-16.348-1.653z"
        fill="#f0f7ff"
      />
      <path
        d="m346.094 284.996v-90c0-4.572.564-9.001 1.348-13.348-5.422-.665-10.75-1.652-16.348-1.652-15.855 0-30.855 3.25-45 8.293v223.337c36.476-30.294 60-75.679 60-126.63z"
        fill="#dfe7f4"
      />
      <circle cx="331.094" cy="74.996" fill="#5a344b" r="15" />
      <g>
        <path
          d="m183.027 373.165c-28.638 0-57.803-11.133-79.644-32.974-80.331-80.332-107.915-197.182-71.981-304.951l8.335-24.99c1.641-4.937 5.713-8.657 10.781-9.858 5.098-1.172 10.371.322 14.048 3.999l190.913 190.913c20.566 20.566 31.479 47.93 30.718 77.021-1.414 53.964-43.768 100.84-103.17 100.84z"
          fill="#daff26"
        />
      </g>
    </g>
  </svg>
);

const springSVGGS = () => (
  <svg
    height="1em"
    viewBox="0 0 512 512"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m481.094 89.996h-60v30h60c8.401 0 15-6.601 15-15 0-8.401-6.599-15-15-15z"
        fill="#6F6F6F"
      />
      <path
        d="m421.094 89.996h-5.4c-6.899 0-11.4-5.7-12.9-11.4-12.601-48.001-57.601-80.8-107.401-78.4-3.182.174-6.191 1.018-9.3 1.5-50.218 7.776-90 53.875-90 108.4 0 14.101-4.2 27.599-12.001 39l-79.799 176.4c-18.3 33.3-28.2 71.1-28.2 109.501v62c0 8.399 6.599 15 15 15s15-6.601 15-15c0-24.901 20.099-47 45-47h30c28.757 0 55.807-7.452 79.385-20.469l25.615-73.013 61.348-174.866c6.321-35.015 36.824-61.652 73.652-61.652 8.401 0 15-6.601 15-15 .001-8.402-6.598-15.001-14.999-15.001z"
        fill="#999999"
      />
      <path
        d="m421.094 119.996c8.401 0 15-6.601 15-15 0-8.401-6.599-15-15-15h-5.4c-6.899 0-11.4-5.7-12.9-11.4-12.601-48.001-57.601-80.8-107.401-78.4-3.182.174-6.191 1.018-9.3 1.5v354.818l61.348-174.866c6.321-35.016 36.825-61.652 73.653-61.652z"
        fill="#6E6E6E"
      />
      <path
        d="m331.094 179.996c-15.855 0-30.855 3.25-45 8.293-52.266 18.636-90 68.119-90 126.707 0 48.481 25.911 90.721 64.385 114.531 9.179-5.067 17.602-11.245 25.615-17.9 36.476-30.295 60-75.679 60-126.63v-90c0-4.572.564-9.001 1.348-13.348-5.422-.666-10.751-1.653-16.348-1.653z"
        fill="#B8BFC6"
      />
      <path
        d="m346.094 284.996v-90c0-4.572.564-9.001 1.348-13.348-5.422-.665-10.75-1.652-16.348-1.652-15.855 0-30.855 3.25-45 8.293v223.337c36.476-30.294 60-75.679 60-126.63z"
        fill="#BEBEBE"
      />
      <circle cx="331.094" cy="74.996" fill="#424242" r="15" />
      <g>
        <path
          d="m183.027 373.165c-28.638 0-57.803-11.133-79.644-32.974-80.331-80.332-107.915-197.182-71.981-304.951l8.335-24.99c1.641-4.937 5.713-8.657 10.781-9.858 5.098-1.172 10.371.322 14.048 3.999l190.913 190.913c20.566 20.566 31.479 47.93 30.718 77.021-1.414 53.964-43.768 100.84-103.17 100.84z"
          fill="#DCDCDC"
        />
      </g>
    </g>
  </svg>
);

export const SpringIcon = (props) => <Icon component={springSVG} {...props} />;
export const SpringIconGS = (props) => (
  <Icon component={springSVGGS} {...props} />
);
export const SummerIcon = (props) => <Icon component={summerSVG} {...props} />;
export const SummerIconGS = (props) => (
  <Icon component={summerSVGGS} {...props} />
);
export const AutumnIcon = (props) => <Icon component={autumnSVG} {...props} />;
export const WinterIcon = (props) => <Icon component={winterSVG} {...props} />;

export const SeasonInput = ({
  value = { spring: false, summer: false, autumn: false, winter: false },
  onChange,
}) => {
  const [seasons, setSeasons] = useState(value);

  const onSwitchChange = (season, v) => {
    const newValue = { ...seasons };
    newValue[season] = v;

    setSeasons(newValue);
    onChange?.(newValue);
  };

  return (
    <Space>
      <Tooltip title="Printemps">
        <Switch
          style={{ backgroundColor: seasons.spring ? "#52c41a" : undefined }}
          checked={seasons.spring}
          checkedChildren={<SpringIcon style={{ fontSize: "1.8em" }} />}
          unCheckedChildren={<SpringIconGS style={{ fontSize: "1.8em" }} />}
          onChange={(e) => onSwitchChange("spring", e)}
        />
      </Tooltip>
      <Tooltip title="Eté">
        <Switch
          style={{ backgroundColor: seasons.summer ? "#fadb14" : undefined }}
          checked={seasons.summer}
          checkedChildren={<SummerIcon style={{ fontSize: "1.8em" }} />}
          unCheckedChildren={<SummerIconGS style={{ fontSize: "1.8em" }} />}
          onChange={(e) => onSwitchChange("summer", e)}
        />
      </Tooltip>
      <Tooltip title="Automne">
        <Switch
          style={{ backgroundColor: seasons.autumn ? "#fa541c" : undefined }}
          checked={seasons.autumn}
          checkedChildren={<AutumnIcon style={{ fontSize: "1.8em" }} />}
          unCheckedChildren={<AutumnIcon style={{ fontSize: "1.8em" }} />}
          onChange={(e) => onSwitchChange("autumn", e)}
        />
      </Tooltip>
      <Tooltip title="Hiver">
        <Switch
          style={{ backgroundColor: seasons.winter ? "#13c2c2" : undefined }}
          checked={seasons.winter}
          checkedChildren={<WinterIcon style={{ fontSize: "1.8em" }} />}
          unCheckedChildren={<WinterIcon style={{ fontSize: "1.8em" }} />}
          onChange={(e) => onSwitchChange("winter", e)}
        />
      </Tooltip>
    </Space>
  );
};

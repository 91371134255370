import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import RecipeActionsComponent from "../components/RecipeActions";
import React from "react";
import { setCheckedRecipe } from "../actions/recipe";

const RecipeDataPrepare = ({ recipes, history, setCheckedRecipe }) => {
  const { id } = useParams();

  const recipe = recipes.find((r) => r.id === id);

  if (recipe !== undefined) {
    return (
      <RecipeActionsComponent
        recipe={recipe}
        history={history}
        setCheckedRecipe={setCheckedRecipe}
      />
    );
  } else {
    return <></>;
  }
};

const Recipe = withRouter(
  connect(
    (state) => {
      return {
        recipes: state.recipes,
      };
    },
    { setCheckedRecipe }
  )(RecipeDataPrepare)
);

export default Recipe;

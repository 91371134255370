import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Input,
  List,
  message,
  Modal,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import {
  AutumnIcon,
  SpringIcon,
  SummerIcon,
  WinterIcon,
} from "./SeasonFormItem";

const { confirm } = Modal;
const { Search } = Input;
const DISPLAYED_RESULTS = 1000

const RecipesList = ({ deleteRecipe, history, recipes = [] }) => {
  useEffect(() => {
    setSearchTerm("");
    setFilteredData(recipes.slice(0, DISPLAYED_RESULTS));
  }, [recipes]);

  const [filteredData, setFilteredData] = useState(recipes);
  const [searchTerm, setSearchTerm] = useState("");

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Confirmer la suppression de la recette?",
      icon: <ExclamationCircleOutlined />,
      content: item.title,
      okText: "Oui",
      okType: "danger",
      cancelText: "Non",
      onOk() {
        deleteRecipe(item.id)
          .then(() => {
            message.success('La recette "' + item.title + '" a été supprimée');
          })
          .catch(() => {
            message.success(
              'La recette sans image "' + item.title + '" a été supprimée'
            );
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const searchResult = recipes.filter(
      (d) =>
        d.title
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(value) !== -1
    ).slice(0, DISPLAYED_RESULTS);

    setFilteredData(searchResult);
    setSearchTerm(value);
  };

  const highlightSearchTerm = (text) => {
    if (!searchTerm || searchTerm === "") {
      return text;
    } else {
      const regEx = new RegExp(searchTerm, "ig");
      const splits = text.split(regEx);
      const matches = [...text.matchAll(regEx)].map((r) => r[0]);
      let mi = 0;

      return splits.reduce((acc, text, i) => {
        acc.push(<span style={{ whiteSpace: "pre-wrap" }}>{text}</span>);
        if (i < splits.length - 1) {
          acc.push(
            <span
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                backgroundColor: "#52c41a6b",
              }}
            >
              {matches[mi++]}
            </span>
          );
        }
        return acc;
      }, []);
    }
  };

  return (
    <>
      <Divider>Recettes</Divider>
      <Search
        placeholder="Rechercher une recette"
        onChange={handleSearch}
        size={"large"}
        enterButton
      />
      <Divider />
      <List
        bordered
        itemLayout="horizontal"
        dataSource={filteredData}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={(item.seasons?.spring ? [<SpringIcon />] : [])
              .concat(
                (item.seasons?.summer ? [<SummerIcon />] : []).concat(
                  (item.seasons?.autumn ? [<AutumnIcon />] : []).concat(
                    item.seasons?.winter ? [<WinterIcon />] : []
                  )
                )
              )
              .concat([
                <Button
                  type="link"
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    history.push("/recette-pdf/" + item.id);
                  }}
                >
                  export PDF
                </Button>,
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push("/recette-edit/" + item.id);
                  }}
                >
                  Modifier
                </Button>,
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    showDeleteConfirm(item);
                  }}
                >
                  Supprimer
                </Button>,
              ])}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  shape="square"
                  style={{ width: 114, height: 64 }}
                  src={item.url}
                />
              }
              title={
                <Button
                  type="link"
                  onClick={() => {
                    history.push("/recette/" + item.id);
                  }}
                >
                  {highlightSearchTerm(item.title)}
                </Button>
              }
              description={
                Array.isArray(item.ingredients) ? (
                  item.ingredients.map((i) => {
                    return (
                      <Tooltip key={i.id} title={i.name}>
                        <Button
                          type="link"
                          size="small"
                          onClick={() => {
                            history.push("/ingredient/edit/" + i.id);
                          }}
                        >
                          <Avatar shape="square" size="small" src={i.url} />
                        </Button>
                      </Tooltip>
                    );
                  })
                ) : (
                  <></>
                )
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default RecipesList;

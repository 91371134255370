import { FETCH_INGREDIENTS_TAGS } from "../actions/types";

const ingredientsTags = (state = [], action) => {
  switch (action.type) {
    case FETCH_INGREDIENTS_TAGS:
      return action.payload;
    default:
      return state;
  }
};

export default ingredientsTags;

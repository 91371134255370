import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as recipeActions from "../actions/recipe";
import RecipePDFComponent from "../components/RecipePDF";
import React from "react";

const RecipeDataPrepare = ({ recipes, recipeTags }) => {
  const { id } = useParams();

  const recipe = recipes.find((r) => r.id === id);

  if (recipe !== undefined) {
    return <RecipePDFComponent recipe={recipe} recipeTags={recipeTags} />;
  } else {
    return <>Recette inconnue"</>;
  }
};

const RecipePDF = connect((state, ownProps) => {
  return {
    recipes: state.recipes,
    recipeTags: state.recipeTags,
  };
}, recipeActions)(RecipeDataPrepare);

export default RecipePDF;

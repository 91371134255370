import React, { useState } from "react";
import { Button } from "antd";
import CropImageModal from "./CropImageModal";
import { UploadOutlined } from "@ant-design/icons";

const CropImageLoaderFormInput = ({ value = {}, onChange }) => {
  const [visible, setVisible] = useState();
  const [dataUrl, setDataUrl] = useState(value.dataUrl);

  const showUserModal = () => {
    setVisible(true);
  };

  const hideUserModal = () => {
    setVisible(false);
  };

  const onOk = (previewUrl) => {
    hideUserModal();
    setDataUrl(previewUrl);
    onChange({ ...value, dataUrl: previewUrl });
  };

  return (
    <>
      {dataUrl ? (
        <img style={{ width: "100%" }} alt="Crop preview" src={dataUrl} />
      ) : (
        value.url && (
          <img style={{ width: "100%" }} alt="Crop preview" src={value.url} />
        )
      )}
      <Button style={{ width: "100%" }} onClick={showUserModal}>
        <UploadOutlined /> Cliquez ici pour télécharger une photo
      </Button>

      <CropImageModal
        value={value}
        visible={visible}
        onCancel={hideUserModal}
        onOk={onOk}
      />
    </>
  );
};
export default CropImageLoaderFormInput;

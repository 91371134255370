import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as recipeActions from "../actions/recipe";
import RecipeTableComponent from "../components/RecipeTable";

const RecipeTable = withRouter(
  connect((data) => {
    return {
      recipes: data.recipes,
      recipeTags: data.recipeTags,
    };
  }, recipeActions)(RecipeTableComponent)
);

export default RecipeTable;

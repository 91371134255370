import { ingredientsStorageRef, recipesStorageRef } from "../firebase";
import { FETCH_INGREDIENT_IMAGES, FETCH_RECIPE_IMAGES } from "./types";

export const fetchIngredientImages = () => async (dispatch) => {
  const references = await ingredientsStorageRef.listAll();
  const imagesData = references.items.map((i) => {
    return {
      name: i.name,
      promise: ingredientsStorageRef.child(i.name).getDownloadURL(),
    };
  });

  const promises = imagesData.map((i) => {
    return i.promise;
  });

  Promise.all(promises).then(function (resolutions) {
    const images = imagesData.reduce((acc, d, i) => {
      acc[d.name] = resolutions[i];
      return acc;
    }, {});

    dispatch({
      type: FETCH_INGREDIENT_IMAGES,
      payload: images,
    });
  });
};

export const uploadIngredientFile = (file) => async () => {
  if (file !== undefined && file !== null) {
    const imageRef = ingredientsStorageRef.child(file.name);
    return imageRef.put(file);
  } else {
    return Promise.resolve();
  }
};

export const fetchRecipeImages = () => async (dispatch) => {
  const references = await recipesStorageRef.listAll();
  const imagesData = references.items.map((i) => {
    return {
      name: i.name,
      promise: recipesStorageRef.child(i.name).getDownloadURL(),
    };
  });

  const promises = imagesData.map((i) => {
    return i.promise;
  });

  Promise.all(promises).then(function (resolutions) {
    const images = imagesData.reduce((acc, d, i) => {
      acc[d.name] = resolutions[i];
      return acc;
    }, {});
    dispatch({
      type: FETCH_RECIPE_IMAGES,
      payload: images,
    });
  });
};

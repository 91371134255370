import { recipeTagsRef, unitiesTagsRef } from "../firebase";
import { FETCH_UNITIES_TAGS, FETCH_RECIPE_TAGS } from "./types";

const clean = (str) => {
  const clean = str.trim().replace(/\s+/g, " ").toLowerCase();
  return clean.charAt(0).toUpperCase() + clean.slice(1);
};

export const addUnitiesTags = (unitiesTags, tags) => async (dispatch) => {
  if (tags !== undefined && tags.length > 0) {
    const added = [];
    tags.forEach((rawTag) => {
      const cleanTagValue = clean(rawTag.name);
      const cleanTagCategory = clean(rawTag.category);

      const existingTag = Object.keys(unitiesTags).find(
        (tId) => clean(unitiesTags[tId].name) === cleanTagValue
      );

      if (
        existingTag === undefined &&
        cleanTagValue !== "" &&
        added.indexOf(cleanTagValue) < 0
      ) {
        added.push(cleanTagValue);
        unitiesTagsRef.push({
          category: cleanTagCategory,
          name: cleanTagValue,
        });
      }
    });
  }
};

export const fetchRecipeTags = () => async (dispatch) => {
  recipeTagsRef.on("value", (snapshot) => {
    const val = snapshot.val();
    let recipeTags = {};

    if (val !== undefined && val !== null) {
      recipeTags = val;
    }

    dispatch({
      type: FETCH_RECIPE_TAGS,
      payload: recipeTags,
    });
  });
};

export const fetchUnitiesTags = () => async (dispatch) => {
  unitiesTagsRef.on("value", (snapshot) => {
    const val = snapshot.val();
    let unitiesTags = {};

    if (val !== undefined && val !== null) {
      unitiesTags = val;
    }

    dispatch({
      type: FETCH_UNITIES_TAGS,
      payload: unitiesTags,
    });
  });
};

import { combineReducers } from "redux";
import ingredients from "./ingredients";
import ingredientsTags from "./ingredientsTags";
import unitiesTags from "./unitiesTags";
import recipeTags from "./recipeTags";
import recipes from "./recipes";
import ingredientImages from "./ingredientImages";
import recipeImages from "./recipeImages";

export default combineReducers({
  ingredientImages,
  recipeImages,
  ingredientsTags,
  unitiesTags,
  recipeTags,
  ingredients,
  recipes,
});

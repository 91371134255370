import "./fonts/fish&chips/fish&chips-Regular.eot";
import "./fonts/fish&chips/fish&chips-Regular.otf";
import "./fonts/fish&chips/fish&chips-Regular.woff";
import "./fonts/fish&chips/fish&chips-Regular.woff2";
import "./fonts/fish&chips/fish&chips-bold.eot";
import "./fonts/fish&chips/fish&chips-bold.otf";
import "./fonts/fish&chips/fish&chips-bold.woff";
import "./fonts/fish&chips/fish&chips-bold.woff2";
import "./fonts/fish&chips/fish&chips-italic.eot";
import "./fonts/fish&chips/fish&chips-italic.otf";
import "./fonts/fish&chips/fish&chips-italic.woff";
import "./fonts/fish&chips/fish&chips-italic.woff2";
import "./fonts/fish&chips/fish&chips-bolditalic.eot";
import "./fonts/fish&chips/fish&chips-bolditalic.otf";
import "./fonts/fish&chips/fish&chips-bolditalic.woff";
import "./fonts/fish&chips/fish&chips-bolditalic.woff2";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

import { recipesRef, recipesStorageRef, TIMESTAMP } from "../firebase";
import { FETCH_RECIPES } from "./types";
import Meal from "../images/meal.svg";

export const addRecipe = (recipe, dataUrl) => async () => {
  for (let prop in recipe) {
    if (recipe[prop] === null || recipe[prop] === undefined) {
      delete recipe[prop];
    }
  }

  const key = recipesRef.push(recipe).key;

  const imageRef = recipesStorageRef.child(key);

  if (dataUrl !== undefined && dataUrl !== null) {
    await imageRef.putString(dataUrl, "data_url");
  }

  return Promise.resolve(key);
};

export const duplicateRecipe = (recipeId) => async () => {
  let key;
  await recipesRef
    .child(recipeId)
    .get()
    .then(async (snapshot) => {
      const val = snapshot.val();
      val.title = val.title + " (copie)";
      key = recipesRef.push(val).key;
    });

  return key;
};

export const updateRecipe =
  (oldRecipe, newRecipe, dataUrl) => async () => {
    if (dataUrl) {
      const imageRef = recipesStorageRef.child(oldRecipe.id);
      imageRef.putString(dataUrl, "data_url");
    }

    const toUpdate = {};
    if (newRecipe.title !== oldRecipe.title) {
      toUpdate.title = newRecipe.title;
    }
    if (newRecipe.quote !== oldRecipe.quote) {
      toUpdate.quote = newRecipe.quote;
    }
    if (newRecipe.nbPerson !== oldRecipe.nbPerson) {
      toUpdate.nbPerson = newRecipe.nbPerson;
    }
    if (newRecipe.seasons !== oldRecipe.seasons) {
      toUpdate.seasons = newRecipe.seasons;
    }
    if (newRecipe.prepTime !== oldRecipe.prepTime) {
      toUpdate.prepTime = newRecipe.prepTime;
    }
    if (newRecipe.restTime !== oldRecipe.restTime) {
      toUpdate.restTime = newRecipe.restTime;
    }
    if (newRecipe.cookTime !== oldRecipe.cookTime) {
      toUpdate.cookTime = newRecipe.cookTime;
    }
    if (JSON.stringify(newRecipe.steps) !== JSON.stringify(oldRecipe.steps)) {
      toUpdate.steps = newRecipe.steps;
    }
    if (newRecipe.tag !== oldRecipe.tag) {
      toUpdate.tag = newRecipe.tag;
    }
    if (newRecipe.notes !== oldRecipe.notes) {
      toUpdate.notes = newRecipe.notes;
    }

    return recipesRef.child(oldRecipe.id).update(toUpdate);
  };

export const setCheckedRecipe = (id, checked) => async () => {
  return recipesRef.child(id).update({ checked: checked ? TIMESTAMP : null });
};

export const deleteRecipe = (recipeId) => async () => {
  await recipesRef.child(recipeId).remove();
  return recipesStorageRef.child(recipeId).delete();
};

export const fetchRecipes = (recipeImages, ingredients) => async (dispatch) => {
  recipesRef.on("value", (snapshot) => {
    const val = snapshot.val();
    let recipes = [];

    if (val !== undefined && val !== null) {
      recipes = Object.keys(val)
        .map((key) => {
          const recipe = val[key];

          const recipeIngredients =
            recipe.ingredients !== undefined && recipe.ingredients.length > 0
              ? recipe.ingredients.map((ingredient) => {
                  if (typeof ingredient === "string") {
                    return ingredients.find((i) => i.id === ingredient);
                  }
                  return {
                    ...ingredient,
                    ...ingredients.find((i) => i.id === ingredient.id),
                  };
                })
              : [];

          return {
            ...recipe,
            url: recipeImages[key] || Meal,
            id: key,
            ingredients: recipeIngredients,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title));
    }

    dispatch({
      type: FETCH_RECIPES,
      payload: recipes,
    });
  });
};

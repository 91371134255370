import { FETCH_RECIPE_TAGS } from "../actions/types";

const recipeTags = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECIPE_TAGS:
      return action.payload;
    default:
      return state;
  }
};

export default recipeTags;

import React, { useEffect, useState } from "react";
import { Tag, Tree } from "antd";

const RecipeTagsViewer = ({ recipeTags }) => {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (recipeTags) {
      const parentTags = Object.keys(recipeTags);
      if (parentTags.length > 0) {
        const map = parentTags.map((pt) => ({
          title: <Tag color={recipeTags[pt].color}>{pt}</Tag>,
          key: pt,
          children: recipeTags[pt].children
            ? Object.keys(recipeTags[pt].children).map((ct) => ({
                title: (
                  <Tag
                    color={recipeTags[pt].children[ct].color}
                    style={{
                      color: recipeTags[pt].children[ct].tcolor
                        ? recipeTags[pt].children[ct].tcolor
                        : "white",
                    }}
                  >
                    {ct}
                  </Tag>
                ),
                key: ct,
              }))
            : [],
        }));
        setTreeData(map);
      }
    }
  }, [recipeTags]);

  return (
    <Tree
      defaultExpandAll
      autoExpandParent
      treeData={treeData}
      expandedKeys={treeData.map((td) => td.key)}
    />
  );
};

export default RecipeTagsViewer;

import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import {
  Avatar,
  Button,
  Divider,
  Image,
  Input,
  message,
  Modal,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  AutumnIcon,
  SpringIcon,
  SummerIcon,
  WinterIcon,
} from "./SeasonFormItem";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FilePdfOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;
const { Search } = Input;

const PUSH_CHARS =
  "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
function decode(id) {
  id = id.substring(0, 8);
  var timestamp = 0;
  for (var i = 0; i < id.length; i++) {
    var c = id.charAt(i);
    timestamp = timestamp * 64 + PUSH_CHARS.indexOf(c);
  }
  return timestamp;
}

const RecipesTable = ({
  deleteRecipe,
  duplicateRecipe,
  recipeTags,
  history,
  recipes = [],
}) => {
  useEffect(() => {
    setSearchTerm("");
    setFilteredData(recipes);
  }, [recipes]);

  const [filteredData, setFilteredData] = useState(recipes);
  const [searchTerm, setSearchTerm] = useState("");

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Confirmer la suppression de la recette?",
      icon: <ExclamationCircleOutlined />,
      content: item.title,
      okText: "Oui",
      okType: "danger",
      cancelText: "Non",
      onOk() {
        deleteRecipe(item.id)
          .then(() => {
            message.success('La recette "' + item.title + '" a été supprimée');
          })
          .catch(() => {
            message.success(
              'La recette sans image "' + item.title + '" a été supprimée'
            );
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Creation",
      dataIndex: "id",
      align: "center",
      render: (id) =>
        new Date(decode(id)).toLocaleString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      sorter: {
        compare: (a, b) => decode(b.id) - decode(a.id),
        multiple: 2,
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Categorie",
      dataIndex: "tag",
      align: "center",
      render: (tag) => {
        const parentTag = tag ? tag.split("/")[0] : "N/A";
        const childTag =
          tag && tag.indexOf("/") >= 0 ? tag.split("/")[1] : undefined;

        const parentTagObj = recipeTags.hasOwnProperty(parentTag)
          ? { ...recipeTags[parentTag], text: parentTag }
          : { color: "red", text: parentTag };

        const childTagObj = childTag
          ? parentTagObj.hasOwnProperty("children") &&
            parentTagObj.children.hasOwnProperty(childTag)
            ? { ...parentTagObj.children[childTag], text: childTag }
            : { color: "red", text: childTag }
          : undefined;

        // const tags = [parentTagObj];

        // uncomment to add child tag
        // if (childTagObj) tags.push(childTagObj);

        return (
          <Button
            type="link"
            onClick={(event) => {
              event.stopPropagation();
              history.push("/rcats");
            }}
          >
            <Tag
              color={parentTagObj.color}
              key={parentTagObj.text}
              style={{
                color: parentTagObj.tcolor ? parentTagObj.tcolor : undefined,
              }}
            >
              {parentTagObj.text}
            </Tag>
            <br />
            {childTagObj ? (
              <Tag
                color={childTagObj.color}
                key={childTagObj.text}
                style={{
                  color: childTagObj.tcolor ? childTagObj.tcolor : undefined,
                }}
              >
                {childTagObj.text}
              </Tag>
            ) : (
              <></>
            )}
          </Button>
        );
      },
      filters: Object.keys(recipeTags)
        .map((key) => ({ text: key, value: key }))
        .concat({ text: "Autres", value: "other" }),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => {
        if (value === "other") {
          return (
            !record.tag ||
            Object.keys(recipeTags).indexOf(record.tag.split("/")[0]) < 0
          );
        } else {
          return record.tag && record.tag.indexOf(value) >= 0;
        }
      },
      sorter: (a, b) => b.tag?.localeCompare(a.tag),
    },
    {
      title: "Photo",
      dataIndex: "url",
      align: "center",
      render: (url) => (
        <Image
          width={114}
          height={64}
          src={url}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    {
      title: "Nom",
      dataIndex: "title",
      align: "center",
      render: (title) => (
        <h2>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchTerm]}
            autoEscape
            textToHighlight={
              title.charAt(0).toUpperCase() + title.toLowerCase().slice(1)
            }
          />
        </h2>
      ),
      sorter: {
        compare: (a, b) => b.title.localeCompare(a.title),
        multiple: 1,
      },
    },
    {
      title: (
        <Tooltip title="Nombre de personnes">
          <TeamOutlined />
        </Tooltip>
      ),
      dataIndex: "nbPerson",
      align: "center",
      render: (nbPerson) => <>{nbPerson}</>,
    },
    {
      title: "Ingredients",
      dataIndex: "ingredients",
      align: "center",
      render: (ingredients, record) => (
        <span>
          {ingredients.map((i) => (
            <Tooltip key={record.id + i.name} title={i.name}>
              <Button
                type="link"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  history.push("/ingredient/edit/" + i.id);
                }}
              >
                <Avatar shape="square" size="small" src={i.url} />
              </Button>
            </Tooltip>
          ))}
        </span>
      ),
    },
    {
      title: "Saisons",
      dataIndex: "seasons",
      align: "center",
      render: (seasons) => (
        <span>
          {seasons?.spring ? <SpringIcon /> : <></>}
          {seasons?.summer ? <SummerIcon /> : <></>}
          {seasons?.autumn ? <AutumnIcon /> : <></>}
          {seasons?.winter ? <WinterIcon /> : <></>}
        </span>
      ),
      filters: [
        { text: "Automne", value: "autumn" },
        { text: "Printemps", value: "spring" },
        { text: "Eté", value: "summer" },
        { text: "Hiver", value: "winter" },
        { text: "Aucune", value: "none" },
      ],
      filterSearch: true,
      onFilter: (value, record) => {
        if (value === "none") {
          return (
            !record.seasons ||
            (!record.seasons.autumn &&
              !record.seasons.spring &&
              !record.seasons.summer &&
              !record.seasons.winter)
          );
        } else {
          return record.seasons && record.seasons[value];
        }
      },
    },
    {
      title: "Étapes",
      dataIndex: "steps",
      align: "center",
      render: (steps) => <span>{(steps || []).length}</span>,
    },
    {
      title: (
        <Tooltip title="Vérifiée">
          <EyeOutlined style={{ color: "#04AA6D" }} />
        </Tooltip>
      ),
      dataIndex: "checked",
      align: "center",
      render: (checked) =>
        checked ? (
          <Tooltip title={new Date(checked).toLocaleString()}>
            <EyeOutlined style={{ color: "#04AA6D" }} />
          </Tooltip>
        ) : (
          <EyeInvisibleOutlined style={{ color: "#FF4D4F" }} />
        ),
      sorter: {
        compare: (a, b) => {
          const aVal = a.checked ? a.checked : 0;
          const bVal = b.checked ? b.checked : 0;
          return bVal - aVal;
        },
        multiple: 3,
      },
    },
    {
      title: "Actions",
      key: "id",
      align: "center",
      fixed: "right",
      render: (id, item) => (
        <span>
          <Tooltip title="Ouvrir PDF">
            <Button
              type="link"
              icon={<FilePdfOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                history.push("/recette-pdf/" + item.id);
              }}
            />
          </Tooltip>
          <Tooltip title="Modifier">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                history.push("/recette-edit/" + item.id);
              }}
            />
          </Tooltip>
          <Tooltip title="Dupliquer">
            <Button
              type="link"
              icon={<CopyOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                duplicateRecipe(item.id)
                  .then((newKey) => {
                    message.success(
                      'La recette "' + item.title + '" a été dupliquée'
                    );
                    history.push("/recette-edit/" + newKey);
                  })
                  .catch((e) => {
                    message.error(e);
                  });
              }}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                showDeleteConfirm(item);
              }}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const handleSearch = (e) => {
    const value = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const searchResult = recipes.filter(
      (d) =>
        d.title
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(value) !== -1
    );

    setFilteredData(searchResult);
    setSearchTerm(value);
  };

  return (
    <>
      <Divider>Recettes</Divider>
      <Search
        placeholder="Rechercher une recette"
        onChange={handleSearch}
        size={"large"}
        enterButton
      />
      <Table
        rowKey="id"
        onRow={(record) => {
          return {
            onClick: () => {
              // history.push("/recette/" + record.id);
              window.open("/recette/" + record.id, "_blank").focus();
            },
          };
        }}
        rowClassName={() => "RecipeTableRow"}
        pagination={{ position: ["topCenter", "bottomCenter"] }}
        columns={columns}
        dataSource={filteredData}
        scroll={{ x: true }}
        // expandable={{
        //   // defaultExpandAllRows: true,
        //   expandedRowRender: (record) => (
        //     <span>
        //       {record.ingredients.map((i) => (
        //         <Tooltip key={i.id} title={i.name}>
        //           <Button
        //             type="link"
        //             size="small"
        //             onClick={() => {
        //               history.push("/ingredient/edit/" + i.id);
        //             }}
        //           >
        //             <Avatar shape="square" size="small" src={i.url} />
        //           </Button>
        //         </Tooltip>
        //       ))}
        //     </span>
        //   ),
        //   rowExpandable: (record) => record.ingredients,
        //}}
      />
    </>
  );
};

export default RecipesTable;

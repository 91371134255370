import Icon from "@ant-design/icons";
import React from "react";

export const snailSvg = () => (
  <svg
    height="1em"
    viewBox="0 -19 511.99911 511"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m447.472656 314.925781 53.589844 41.191407c12.257812 9.421874 14.558594 27 5.136719 39.257812-5.515625 7.171875-13.820313 10.9375-22.21875 10.9375-5.953125 0-11.953125-1.890625-17.039063-5.800781l-53.59375-41.195313zm0 0"
      fill="#ffb25a"
    />
    <path
      d="m324.929688 310.34375c0 89.726562-72.738282 162.464844-162.464844 162.464844-89.726563 0-162.464844-72.738282-162.464844-162.464844s72.738281-162.464844 162.464844-162.464844c89.726562 0 162.464844 72.738282 162.464844 162.464844zm0 0"
      fill="#de8957"
    />
    <path
      d="m162.464844 409.234375c-54.527344 0-98.890625-44.363281-98.890625-98.890625 0-54.53125 44.363281-98.894531 98.890625-98.894531 54.53125 0 98.894531 44.363281 98.894531 98.894531 0 54.527344-44.363281 98.890625-98.894531 98.890625zm0 0"
      fill="#c76d33"
    />
    <path
      d="m162.464844 345.660156c-19.476563 0-35.316406-15.84375-35.316406-35.316406 0-19.476562 15.84375-35.320312 35.316406-35.320312 19.476562 0 35.320312 15.84375 35.320312 35.320312s-15.84375 35.316406-35.320312 35.316406zm0 0"
      fill="#de8957"
    />
    <path
      d="m311.875 159.4375c-5.960938 0-11.675781-3.371094-14.386719-9.121094-21.328125-45.242187-7.523437-80.597656 7.816407-102.292968 15.957031-22.566407 36.769531-34.78125 37.648437-35.289063 7.597656-4.398437 17.320313-1.808594 21.722656 5.785156 4.390625 7.582031 1.816407 17.28125-5.742187 21.695313-.40625.242187-16.453125 9.894531-28.222656 26.945312-14.699219 21.300782-16.203126 44.714844-4.46875 69.601563 3.742187 7.941406.339843 17.410156-7.597657 21.15625-2.191406 1.03125-4.5 1.519531-6.769531 1.519531zm0 0"
      fill="#ffb25a"
    />
    <path
      d="m401.480469 154.660156c-7.605469 0-14.324219-5.472656-15.648438-13.21875-8.425781-49.300781 14.347657-79.667968 34.9375-96.457031 21.421875-17.46875 44.75-23.65625 45.730469-23.910156 8.492188-2.203125 17.171875 2.898437 19.375 11.394531 2.199219 8.480469-2.875 17.136719-11.347656 19.363281-.523438.144531-18.53125 5.148438-34.40625 18.398438-19.867188 16.582031-27.59375 38.738281-22.957032 65.859375 1.476563 8.652344-4.335937 16.863281-12.988281 18.34375-.90625.152344-1.808593.226562-2.695312.226562zm0 0"
      fill="#ffb25a"
    />
    <path
      d="m353.894531 104.867188c-60.078125 0-108.785156 48.703124-108.785156 108.78125 0 216.855468-82.644531 259.160156-82.644531 259.160156h149.273437c83.359375 0 150.9375-67.578125 150.9375-150.9375v-108.222656c0-60.078126-48.703125-108.78125-108.78125-108.78125zm0 0"
      fill="#f8d070"
    />
    <path
      d="m276.015625 213.648438c0-54.832032 40.570313-100.179688 93.328125-107.683594-5.046875-.71875-10.207031-1.097656-15.453125-1.097656-60.078125 0-108.78125 48.703124-108.78125 108.78125 0 216.855468-82.644531 259.160156-82.644531 259.160156h30.90625s82.644531-42.304688 82.644531-259.160156zm0 0"
      fill="#ffb25a"
    />
    <g fill="#333a41">
      <path d="m311.863281 215.714844c-4.269531 0-7.726562-3.460938-7.726562-7.726563v-9.882812c0-4.265625 3.457031-7.726563 7.726562-7.726563s7.726563 3.460938 7.726563 7.726563v9.882812c0 4.265625-3.457032 7.726563-7.726563 7.726563zm0 0" />
      <path d="m421.0625 215.714844c-4.269531 0-7.726562-3.460938-7.726562-7.726563v-9.882812c0-4.265625 3.457031-7.726563 7.726562-7.726563 4.265625 0 7.726562 3.460938 7.726562 7.726563v9.882812c0 4.265625-3.460937 7.726563-7.726562 7.726563zm0 0" />
      <path d="m366.460938 220.988281c-7.480469 0-14.511719-3.136719-19.292969-8.601562-2.808594-3.210938-2.480469-8.09375.734375-10.902344 3.210937-2.808594 8.089844-2.480469 10.902344.730469 1.84375 2.109375 4.636718 3.320312 7.65625 3.320312 3.023437 0 5.816406-1.210937 7.660156-3.320312 2.808594-3.210938 7.691406-3.539063 10.902344-.730469 3.210937 2.808594 3.542968 7.6875.734374 10.902344-4.78125 5.464843-11.8125 8.601562-19.296874 8.601562zm0 0" />
    </g>
    <path
      d="m346.242188 414.039062c-7.929688 0-15.453126-2.558593-21.75-7.402343l-53.589844-41.195313c-3.386719-2.597656-4.019532-7.449218-1.417969-10.832031 2.597656-3.382813 7.449219-4.019531 10.832031-1.417969l53.59375 41.195313c3.574219 2.746093 7.839844 4.199219 12.332032 4.199219 6.359374 0 12.222656-2.882813 16.089843-7.917969 3.300781-4.292969 4.730469-9.613281 4.027344-14.980469-.699219-5.367188-3.453125-10.140625-7.742187-13.441406l-53.59375-41.191406c-3.382813-2.601563-4.015626-7.453126-1.417969-10.835938 2.601562-3.382812 7.453125-4.019531 10.835937-1.417969l53.59375 41.195313c7.5625 5.8125 12.410156 14.226562 13.648438 23.6875 1.238281 9.460937-1.285156 18.839844-7.097656 26.40625-6.816407 8.863281-17.148438 13.949218-28.34375 13.949218zm0 0"
      fill="#ffb25a"
    />
    <path
      d="m376.910156 26.488281c0 14.351563-11.636718 25.984375-25.988281 25.984375s-25.988281-11.632812-25.988281-25.984375c0-14.355469 11.636718-25.988281 25.988281-25.988281s25.988281 11.632812 25.988281 25.988281zm0 0"
      fill="#f8d070"
    />
    <path
      d="m477.429688 11.433594c-13.828126-3.84375-28.15625 4.246094-32 18.074218-3.84375 13.828126 4.246093 28.152344 18.074218 32 13.828125 3.84375 28.152344-4.25 32-18.074218 3.84375-13.828125-4.25-28.15625-18.074218-32zm0 0"
      fill="#f8d070"
    />
  </svg>
);

export const SnailIcon = (props) => <Icon component={snailSvg} {...props} />;

import { connect } from "react-redux";
import * as tagActions from "../actions/tag";
import RecipeTagsEditorComponent from "../components/RecipeTagViewer";

const RecipeTagViewer = connect((data) => {
  return {
    recipeTags: data.recipeTags,
  };
}, tagActions)(RecipeTagsEditorComponent);

export default RecipeTagViewer;

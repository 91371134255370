import React from "react";
import { Button, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const StepsFormItem = ({ label, name }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <Form.Item
                {...formItemLayout}
                label={label + " " + (index + 1)}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Saisissez une étape ou éffacez ce champ vide.",
                    },
                  ]}
                  noStyle
                >
                  <Input.TextArea
                    placeholder="Saisissez le détail de l'étape"
                    style={{ width: "calc(100% - 30px)" }}
                  />
                </Form.Item>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                style={{ width: "80%" }}
              >
                <PlusOutlined /> Ajouter une étape
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default StepsFormItem;

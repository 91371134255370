import { connect } from "react-redux";
import * as imageActions from "../actions/image";
import { useEffect } from "react";

const ImageLoaderComponent = ({
  recipeImages,
  fetchRecipeImages,
  children,
}) => {
  useEffect(() => {
    fetchRecipeImages();
  }, [fetchRecipeImages]);

  return Object.keys(recipeImages).length > 0 ? children : null;
};

const RecipeImagesLoader = connect((data) => {
  return { recipeImages: data.recipeImages };
}, imageActions)(ImageLoaderComponent);

export default RecipeImagesLoader;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  ConfigProvider,
  Empty,
  Modal,
  Space,
  Tooltip,
  Transfer,
} from "antd";
import Product from "../images/product.svg";
import { PlusCircleTwoTone } from "@ant-design/icons";
import AddIngredient from "../containers/AddIngredient";

const IngredientsFormInput = ({ value = [], data, onChange }) => {
  const [targetKeys, setTargetKeys] = useState(value);
  const [ingredientToAdd, setIngredientToAdd] = useState();
  const [visible, setVisible] = useState(false);
  const [lastSearch, setLastSearch] = useState("");

  useEffect(() => {
    if (ingredientToAdd !== undefined) {
      const key = data.find((i) => i.name === ingredientToAdd).key;
      setIngredientToAdd(undefined);
      const newTK = targetKeys.concat(key);
      setTargetKeys(newTK);
      onChange(newTK);
    }
  }, [ingredientToAdd]);

  const handleChange = (targetKeys, direction, moveKeys) => {
    if (direction === "right") {
      // TODO clean search if possible
    }
    setTargetKeys(targetKeys);
    onChange(targetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log(sourceSelectedKeys);
  };

  const handleSearch = (dir, value) => {
    if (dir === "left") {
      setLastSearch(value);
    }
  };

  const filterOption = (inputValue, option) => {
    return (
      option.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .indexOf(
          inputValue
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) > -1
    );
  };

  const customizeRenderEmpty = () => (
    <Empty
      image={Product}
      description={<span>Aucun ingrédient correspondant</span>}
    >
      {" "}
      <Tooltip title="Créer un nouvel ingrédient">
        <Button
          style={{
            height: 0,
            padding: 0,
            fontSize: "14px",
          }}
          type="link"
          icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
          onClick={showModal}
        >
          Ajouter "{lastSearch}" dans la liste
        </Button>
      </Tooltip>
    </Empty>
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (ingredientName) => {
    setIngredientToAdd(ingredientName);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Transfer
        titles={[
          <Tooltip title="Créer un nouvel ingrédient">
            <Button
              style={{
                height: 0,
                padding: 0,
                fontSize: "14px",
              }}
              type="link"
              icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
              onClick={showModal}
            />
          </Tooltip>,
        ]}
        dataSource={data}
        showSearch
        showSelectAll={false}
        targetKeys={targetKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        onSearch={handleSearch}
        oneWay={true}
        filterOption={filterOption}
        render={(item) => (
          <Space>
            <Avatar shape="square" size="small" src={item.url} />
            {item.name}
          </Space>
        )}
        listStyle={{
          width: "calc(50% - 20px)",
          height: 300,
        }}
      />
      <Modal
        title="Ajouter un Ingredient"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <AddIngredient name={lastSearch} onSuccess={handleOk} />
      </Modal>
    </ConfigProvider>
  );
};

export default IngredientsFormInput;

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as recipeActions from "../actions/recipe";
import RecipeListComponent from "../components/RecipeList";

const RecipeList = withRouter(
  connect((data) => {
    return {
      recipes: data.recipes,
      recipeTags: data.recipeTags,
    };
  }, recipeActions)(RecipeListComponent)
);

export default RecipeList;

import React, { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Tag,
  Tooltip,
} from "antd";
import {
  CameraOutlined,
  CloudUploadOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import CropImageLoaderFormInput from "./CropImageLoaderFormInput";
// import IngredientsFormInput from "./IngredientsFormInput";
import StepsFormItem from "./StepsFormItem";
// import QuantitiesFormItem from "./QuantitiesFormItem";
import { Prompt } from "react-router-dom";
import { SeasonInput } from "./SeasonFormItem";
import { SnailIcon } from "./SnailIcon";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const { Option, OptGroup } = Select;

const cleanValues = (recipe) => {
  return {
    title: recipe.title,
    quote: recipe.quote,
    nbPerson: recipe.nbPerson,
    prepTime: recipe.prepTime,
    restTime: recipe.restTime,
    cookTime: recipe.cookTime,
    seasons: recipe.seasons,
    // ingredients:
    //   recipe.ingredients !== undefined
    //     ? recipe.ingredients
    //         .map((i) => {
    //           return {
    //             id: i,
    //             quantity: recipe.quantities[i].quantity,
    //             unit: recipe.quantities[i].unit,
    //           };
    //         })
    //         .sort((a, b) => {
    //           return (
    //             recipe.quantities[a.id].order - recipe.quantities[b.id].order
    //           );
    //         })
    //     : undefined,
    steps: recipe.steps,
    tag: recipe.tag,
    notes: recipe.notes,
  };
};

// const cleanUnityTags = (recipe) => {
//   return recipe.ingredients !== undefined
//     ? recipe.ingredients.map((i) => {
//         return {
//           category: "Autre",
//           name: recipe.quantities[i].unit,
//         };
//       })
//     : [];
// };

const EditRecipe = ({
  recipe,
  recipes,
  recipeTags,
  ingredients,
  unitiesTags,
  addUnitiesTags,
  history,
  updateRecipe,
  fetchRecipeImages,
  preventDefault = true,
}) => {
  React.useEffect(() => {
    if (!preventDefault) return;
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [preventDefault]);

  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [recipeTagsOptions, RecipeTagsOptions] = useState([]);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (recipeTags) {
      RecipeTagsOptions(
        Object.keys(recipeTags).map((key) => {
          if (recipeTags[key].children) {
            return (
              <OptGroup label={key} key={key}>
                {Object.keys(recipeTags[key].children).map((cKey) => (
                  <Option value={key + "/" + cKey} key={key + "/" + cKey}>
                    <Tag color={recipeTags[key].children[cKey].color}>
                      {cKey}
                    </Tag>
                  </Option>
                ))}
              </OptGroup>
            );
          } else {
            return (
              <Option value={key} key={key}>
                <Tag color={recipeTags[key].color}>{key}</Tag>
              </Option>
            );
          }
        })
      );
    }
  }, [recipeTags]);

  const onFinish = (values) => {
    setLoading(true);
    const dataUrl =
      values.hasOwnProperty("picture") && values.picture !== undefined
        ? values.picture.dataUrl
        : undefined;

    // addUnitiesTags(unitiesTags, cleanUnityTags(values));

    updateRecipe(recipe, cleanValues(values), dataUrl).then(() => {
      setLoading(false);
      message.success('La recette "' + values.title + '" a été modifiée');
      setPrompt(false);
      fetchRecipeImages();
      history.push("/recette/" + recipe.id);
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("La recette ne peut pas être ajoutée");
    console.log("Failed:", errorInfo);
  };

  const normalizedTitle = (title) => {
    const clean = title.trim().replace(/\s+/g, " ");
    return (
      clean.charAt(0).toUpperCase() +
      clean.slice(1) +
      (title.endsWith(" ") ? " " : "")
    );
  };

  const onTitleChange = (e) => {
    const value = e.target.value;
    form.setFieldsValue({ title: normalizedTitle(value) });
  };

  const titleIsValid = (title) => {
    if (title === recipe.title) {
      return true;
    }
    const existing = recipes.reduce((acc, i) => {
      acc.push(i.title.toLowerCase());
      return acc;
    }, []);
    return existing.indexOf(title.trim().toLowerCase()) < 0;
  };

  return (
    <>
      <Prompt
        when={prompt}
        message="Les modifications que vous avez apportées ne seront pas enregistrées voulez vous quand même quitter la page?"
      />
      <Divider>Editer une Recette</Divider>
      <Form
        {...layout}
        form={form}
        name="add-recipe"
        size="large"
        initialValues={{
          title: recipe.title,
          quote: recipe.quote,
          nbPerson: recipe.nbPerson,
          prepTime: recipe.prepTime,
          restTime: recipe.restTime,
          cookTime: recipe.cookTime,
          seasons: recipe.seasons,
          steps: recipe.steps,
          ingredients: recipe.ingredients,
          tag: recipe.tag,
          notes: recipe.notes,
          picture: { url: recipe.url },
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onKeyDown={(e) => {
          // Avoid submit form when hitting enter key but keep carriage return on text area
          if (e.keyCode === 13 && e.target.nodeName !== "TEXTAREA") {
            e.preventDefault();
          }
        }}
        onValuesChange={() => {
          setPrompt(true);
        }}
      >
        <Form.Item
          label="Titre"
          name="title"
          rules={[
            { required: true, message: "Le titre est requis!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value === undefined || titleIsValid(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("La recette existe déja!");
              },
            }),
          ]}
        >
          <Input
            onChange={onTitleChange}
            placeholder="Definir le titre de la recette."
          />
        </Form.Item>

        <Form.Item label="Catégorie" name="tag">
          <Select placeholder="Choisir une catégorie">
            {recipeTagsOptions}
          </Select>
        </Form.Item>

        <Form.Item label="Remarque" name="quote">
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <Tooltip title="Photo">
              <CameraOutlined />
            </Tooltip>
          }
          name="picture"
        >
          <CropImageLoaderFormInput />
        </Form.Item>

        <Form.Item
          name="nbPerson"
          label={
            <Tooltip title="Nombre de personnes">
              <SnailIcon style={{ fontSize: "28px" }} />
            </Tooltip>
          }
        >
          <InputNumber min={1} max={15} />
        </Form.Item>

        <Form.Item name="seasons" label="Saisons">
          <SeasonInput />
        </Form.Item>

        <Form.Item
          name="prepTime"
          label={
            <Tooltip title="Préparation">
              <FieldTimeOutlined />
            </Tooltip>
          }
        >
          <InputNumber min={0} max={1440} />
        </Form.Item>

        <Form.Item
          name="restTime"
          label={
            <Tooltip title="Repos">
              <HistoryOutlined />
            </Tooltip>
          }
        >
          <InputNumber min={0} max={1440} />
        </Form.Item>

        <Form.Item
          name="cookTime"
          label={
            <Tooltip title="Cuisson">
              <DashboardOutlined />
            </Tooltip>
          }
        >
          <InputNumber min={0} max={1440} />
        </Form.Item>

        {/*<Form.Item label="Ingredients" name="ingredients">*/}
        {/*  <IngredientsFormInput*/}
        {/*    data={*/}
        {/*      Array.isArray(ingredients)*/}
        {/*        ? ingredients.map((i) => {*/}
        {/*            return {*/}
        {/*              ...i,*/}
        {/*              key: i.id,*/}
        {/*            };*/}
        {/*          })*/}
        {/*        : []*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Form.Item>*/}

        {/*<QuantitiesFormItem*/}
        {/*  ingredients={ingredients}*/}
        {/*  unitiesTags={unitiesTags}*/}
        {/*  label="Quantités"*/}
        {/*  name="quantities"*/}
        {/*/>*/}

        <StepsFormItem label="Étape" name="steps" />

        <Form.Item label="Notes" name="notes">
          <Input.TextArea />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            <CloudUploadOutlined />
            Mettre à jour
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditRecipe;

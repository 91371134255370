import React from "react";
import { Avatar, Badge, Divider, Layout, List, Space, Typography } from "antd";
import { FormOutlined, BulbOutlined } from "@ant-design/icons";
import {
  AutumnIcon,
  SpringIcon,
  SummerIcon,
  WinterIcon,
} from "./SeasonFormItem";
import { SnailIcon } from "./SnailIcon";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const Recipe = ({ recipe, recipeTags, history }) => {
  function getRecipeTagColor(tag) {
    if (!tag) return { color: "red", tcolor: "white" };
    const paths = tag.split("/");

    if (paths.length > 1 && recipeTags.hasOwnProperty(paths[0])) {
      if (recipeTags[paths[0]].children.hasOwnProperty(paths[1])) {
        return {
          color: recipeTags[paths[0]].children[paths[1]].color,
          tcolor: recipeTags[paths[0]].children[paths[1]].tcolor,
        };
      } else {
        return {
          color: recipeTags[paths[0]].color,
          tcolor: recipeTags[paths[0]].tcolor,
        };
      }
    } else if (recipeTags.hasOwnProperty(paths[0])) {
      return {
        color: recipeTags[paths[0]].color,
        tcolor: recipeTags[paths[0]].tcolor,
      };
    }

    return "red";
  }

  return (
    <Layout
      id="section-to-print"
      style={{
        background: "none",
        margin: "0 auto",
        textAlign: "center",
        textJustify: "auto",
        position: "relative",
        width: "14.8cm",
        minHeight: "21cm",
      }}
    >
      <Layout
        style={{
          background: "none",
          margin: 0,
        }}
      >
        <Space style={{ height: "1.5em", lineHeight: "1.5em" }}>
          {recipe.seasons?.spring ? (
            <SpringIcon style={{ fontSize: "1.5em" }} />
          ) : (
            <></>
          )}
          {recipe.seasons?.summer ? (
            <SummerIcon style={{ fontSize: "1.5em" }} />
          ) : (
            <></>
          )}
          {recipe.seasons?.autumn ? (
            <AutumnIcon style={{ fontSize: "1.5em" }} />
          ) : (
            <></>
          )}
          {recipe.seasons?.winter ? (
            <WinterIcon style={{ fontSize: "1.5em" }} />
          ) : (
            <></>
          )}
        </Space>
        <Header
          style={{
            background: "none",
            height: "auto",
            padding: 0,
            margin: 0,
          }}
        >
          <Title
            className="camelCase"
            style={{
              background: "none",
              lineHeight: "1.5cm",
              fontSize: "42px",
              padding: 0,
              margin: 0,
            }}
          >
            {recipe.title}
          </Title>
        </Header>
        <Content style={{ background: "none" }}>
          <div style={{ minHeight: "19cm" }}>
            <Badge.Ribbon
              placement="end"
              text={
                recipe.tag && (
                  <span style={{ color: getRecipeTagColor(recipe.tag).tcolor }}>
                    {recipe.tag.split("/")[recipe.tag.split("/").length - 1]}
                  </span>
                )
              }
              color={getRecipeTagColor(recipe.tag).color}
            >
              <img style={{ width: "100%" }} alt="recipe" src={recipe.url} />
            </Badge.Ribbon>

            {recipe.nbPerson ? (
              <Divider orientation={"left"}>
                Pour {recipe.nbPerson || "?"}{" "}
                <SnailIcon style={{ fontSize: "28px" }} />
              </Divider>
            ) : (
              <Divider orientation={"right"}>
                <SnailIcon
                  style={{ fontSize: "28px", transform: "scaleX(-1)" }}
                />
              </Divider>
            )}
            <List
              style={{ textAlign: "left" }}
              grid={{
                gutter: 8,
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
                xxl: 3,
              }}
              size="small"
              dataSource={recipe.ingredients}
              renderItem={(item) => (
                <List.Item
                  style={{ cursor: "pointer", breakInside: "avoid" }}
                  onClick={() => {
                    history.push("/ingredient/edit/" + item.id);
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar shape="square" size="small" src={item.url} />
                    }
                    title={item.name}
                    description={
                      (item.quantity ? item.quantity : "") +
                      " " +
                      (item.unit
                        ? item.unit === "unity" && item.unitName !== undefined
                          ? item.unitName
                          : item.unit
                        : "")
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          {recipe.quote ? (
            <div style={{ textAlign: "left", pageBreakBefore: "always" }}>
              <BulbOutlined /> {recipe.quote}
            </div>
          ) : (
            <></>
          )}
          <Divider
            style={recipe.quote ? {} : { pageBreakBefore: "always" }}
            orientation={"left"}
          >
            Étapes:
          </Divider>
          <List
            style={{ textAlign: "left" }}
            dataSource={recipe.steps}
            renderItem={(item, i) => (
              <List.Item key={item.id}>
                <Typography.Text>{i + 1}.</Typography.Text> {item}
              </List.Item>
            )}
          />
          {recipe.notes ? (
            <Footer
              style={{
                backgroundColor: "#ffffff",
                textAlign: "left",
                paddingTop: "0.5cm",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <FormOutlined /> {recipe.notes}
            </Footer>
          ) : (
            <></>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Recipe;

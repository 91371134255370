import { connect } from "react-redux";
import * as tagsActions from "../actions/tag";
import { useEffect } from "react";

const UnitiesTagsLoaderComponent = ({ fetchUnitiesTags, children }) => {
  useEffect(() => {
    fetchUnitiesTags();
  }, [fetchUnitiesTags]);

  return children;
};

const IngredientsTagsLoader = connect((data) => {
  return {
    unitiesTags: data.unitiesTags,
  };
}, tagsActions)(UnitiesTagsLoaderComponent);

export default IngredientsTagsLoader;

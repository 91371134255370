import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Form,
  InputNumber,
  List,
  Tooltip,
} from "antd";
import { UpOutlined } from "@ant-design/icons";

import _ from "lodash";

const createDefaultData = (ingredients) => {
  return ingredients.reduce((acc, ingredient, order) => {
    acc[ingredient.id] = {
      ...ingredient,
      quantity: 1,
      unit: "",
      order,
    };

    return acc;
  }, {});
};

const createUnityTagsOptions = (searchText, unitiesTags) => {
  const tags = Object.keys(unitiesTags).reduce((acc, utId) => {
    if (
      unitiesTags[utId].name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase()
        .indexOf(
          searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase()
        ) !== -1
    ) {
      if (!acc.hasOwnProperty(unitiesTags[utId].category)) {
        acc[unitiesTags[utId].category] = [];
      }
      acc[unitiesTags[utId].category].push({
        label: unitiesTags[utId].name,
        value: unitiesTags[utId].name,
      });
    }

    return acc;
  }, {});
  return Object.keys(tags).map((t) => {
    return {
      label: t,
      options: tags[t],
    };
  });
};

const createDefaultOptions = (ingredients, unitiesTags) => {
  return ingredients.reduce((acc, ingredient) => {
    acc[ingredient.id] = createUnityTagsOptions("", unitiesTags);
    return acc;
  }, {});
};

/********************* QuantityListInput */
const QuantityListInput = ({ onChange, ingredients, unitiesTags }) => {
  const [data, setData] = useState(createDefaultData(ingredients));
  const [index, setIndex] = useState(ingredients.length);
  const [options, setOptions] = useState(
    createDefaultOptions(ingredients, unitiesTags)
  );

  useEffect(() => {
    const defaultData = createDefaultData(ingredients);
    const clone = _.cloneDeep(data);
    const optionsClone = _.cloneDeep(options);
    let newItemIndex = _.clone(index);

    // Delete removed
    Object.keys(clone).forEach((id) => {
      if (!defaultData.hasOwnProperty(id)) {
        delete clone[id];
        delete optionsClone[id];
      }
    });

    // Add new
    Object.keys(defaultData).forEach((id) => {
      if (!clone.hasOwnProperty(id)) {
        clone[id] = defaultData[id];
        clone[id].order = newItemIndex++;
        optionsClone[id] = createUnityTagsOptions("", unitiesTags);
      }
    });

    setData(clone);
    setIndex(newItemIndex);
    setOptions(optionsClone);
    onChange({
      ...clone,
    });
  }, [ingredients]);

  const onQuantityChange = (quantity, id) => {
    const clone = _.cloneDeep(data);
    clone[id].quantity = quantity;
    setData(clone);
    onChange({
      ...clone,
    });
  };

  const onUnitChange = (unit, id) => {
    const clone = _.cloneDeep(data);
    clone[id].unit = unit;
    setData(clone);

    const cloneOptions = _.cloneDeep(options);
    cloneOptions[id] = createUnityTagsOptions(unit, unitiesTags);
    setOptions(cloneOptions);

    onChange({
      ...clone,
    });
  };

  const moveUp = (ingredient) => {
    const order = ingredient.order;
    let clone = _.cloneDeep(data);
    let upperIngredient;

    Object.keys(clone).forEach((id) => {
      if (clone[id].order < order) {
        if (
          upperIngredient === undefined ||
          clone[id].order > upperIngredient.order
        ) {
          upperIngredient = clone[id];
        }
      }
    });

    if (upperIngredient !== undefined) {
      clone[ingredient.id].order = upperIngredient.order;
      clone[upperIngredient.id].order = order;
    }

    setData(clone);
    onChange({
      ...clone,
    });
  };

  const onSearch = (searchText, id) => {
    const clone = _.cloneDeep(options);
    clone[id] = createUnityTagsOptions(searchText, unitiesTags);
    setOptions(clone);
  };

  return (
    <List
      size="small"
      dataSource={Object.keys(data).sort(
        (idA, idB) => data[idA].order - data[idB].order
      )}
      renderItem={(id, index) => (
        <List.Item
          actions={[
            <InputNumber
              name="quantity"
              key={id}
              value={data[id].quantity}
              onChange={(val) => onQuantityChange(val, id)}
              min={0}
            />,

            <AutoComplete
              // dropdownMatchSelectWidth={500}
              style={{
                width: 250,
              }}
              options={options[id]}
              onSearch={(searchText) => onSearch(searchText, id)}
              placeholder="Saisir l'unité"
              value={data[id].unit}
              onChange={(val) => onUnitChange(val, id)}
            />,
            <Tooltip title="Monter l'ingredient" placement="right">
              <Button
                type="primary"
                size="small"
                shape="circle"
                disabled={index === 0}
                onClick={() => moveUp(data[id])}
              >
                <UpOutlined />
              </Button>
            </Tooltip>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar shape="square" size="small" src={data[id].url} />}
            title={data[id].name}
          />
        </List.Item>
      )}
    />
  );
};

const QuantitiesFormItem = ({ ingredients, unitiesTags, label, name }) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        let hasChanged;
        if (
          prevValues.ingredients === undefined ||
          curValues.ingredients === undefined
        ) {
          hasChanged = prevValues.ingredients !== curValues.ingredients;
        } else {
          hasChanged =
            prevValues.ingredients.join() !== curValues.ingredients.join();
        }

        return hasChanged;
      }}
    >
      {({ getFieldValue }) => {
        const ingredientIds = getFieldValue("ingredients");
        if (
          ingredientIds === undefined ||
          ingredientIds === null ||
          ingredientIds.length <= 0
        ) {
          return null;
        } else {
          const selectedIngredients = ingredientIds.map((ingredientId) =>
            ingredients.find((ingredient) => ingredient.id === ingredientId)
          );
          return (
            <Form.Item label={label} name={name}>
              <QuantityListInput
                ingredients={selectedIngredients}
                unitiesTags={unitiesTags}
              />
            </Form.Item>
          );
        }
      }}
    </Form.Item>
  );
};

export default QuantitiesFormItem;

import { connect } from "react-redux";
import * as tagActions from "../actions/tag";
import TagsEditorComponent from "../components/TagsEditor";

const TagsEditor = connect((data) => {
  return {
    unitiesTags: data.unitiesTags,
  };
}, tagActions)(TagsEditorComponent);

export default TagsEditor;

import { ingredientsRef, ingredientsTagsRef } from "../firebase";
import { FETCH_INGREDIENTS, FETCH_INGREDIENTS_TAGS } from "./types";
import Engineer from "../images/engineer.svg";

const PresetColors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];

export const addIngredient = (ingredient) => async () => {
  for (let prop in ingredient) {
    if (ingredient.hasOwnProperty(prop)) {
      if (ingredient[prop] === null || ingredient[prop] === undefined) {
        delete ingredient[prop];
      }
    }
  }
  ingredientsRef.push(ingredient);
};

export const updateIngredient = (id, ingredient) => async () => {
  const update = {};
  update[id] = ingredient;
  await ingredientsRef.update(update);
};

export const deleteIngredient = (ingredientId) => async () => {
  await ingredientsRef.child(ingredientId).remove();
};

export const fetchIngredients = (ingredientImages) => async (dispatch) => {
  ingredientsRef.on("value", (snapshot) => {
    const val = snapshot.val();
    let ingredients = [];

    if (val !== undefined && val !== null) {
      ingredients = Object.keys(val)
        .map((key) => {
          const ingredient = val[key];
          return {
            ...ingredient,
            url: ingredientImages[ingredient.img] || Engineer,
            id: key,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    dispatch({
      type: FETCH_INGREDIENTS,
      payload: ingredients,
    });
  });
};

const clean = (str) => {
  const clean = str.trim().replace(/\s+/g, " ").toLowerCase();
  return clean.charAt(0).toUpperCase() + clean.slice(1);
};

export const addIngredientsTags = (ingredientsTags, tags) => async () => {
  if (tags !== undefined && tags.length > 0) {
    tags.forEach((rawTagValue) => {
      const cleanTagValue = clean(rawTagValue);

      const existingTag = Object.keys(ingredientsTags).find(
        (tId) => ingredientsTags[tId].name === cleanTagValue
      );

      if (existingTag === undefined) {
        const tagColor =
          PresetColors[Math.floor(Math.random() * PresetColors.length)];
        ingredientsTagsRef.push({ name: cleanTagValue, color: tagColor });
      }
    });
  }
};

export const fetchIngredientsTags = () => async (dispatch) => {
  ingredientsTagsRef.on("value", (snapshot) => {
    const val = snapshot.val();
    let ingredientsTags = {};

    if (val !== undefined && val !== null) {
      ingredientsTags = val;
    }

    dispatch({
      type: FETCH_INGREDIENTS_TAGS,
      payload: ingredientsTags,
    });
  });
};

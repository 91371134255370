import React from "react";
import { Button, Space, Switch } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  FilePdfOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

const Recipe = ({ recipe, history, setCheckedRecipe }) => {
  return (
    <>
      <Space>
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          size="large"
          onClick={() => {
            history.push("/recette-edit/" + recipe.id);
          }}
        >
          Modifier
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon={<FilePdfOutlined />}
          onClick={() => {
            history.push("/recette-pdf/" + recipe.id);
          }}
        >
          Version PDF
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon={<PrinterOutlined />}
          onClick={() => {
            window.print();
            return false;
          }}
        >
          Imprimer
        </Button>
      </Space>

      <Switch
        size="large"
        style={{
          float: "right",
          borderColor: recipe.checked ? "#04AA6D" : "#FF4D4F",
          background: recipe.checked ? "#04AA6D" : "#FF4D4F",
        }}
        checkedChildren={
          <Space>
            <CheckOutlined />
            Vérifiée
          </Space>
        }
        unCheckedChildren={
          <Space>
            <CloseOutlined />
            Non vérifiée
          </Space>
        }
        checked={recipe.checked}
        onChange={(value) => {
          setCheckedRecipe(recipe.id, value);
        }}
      />
    </>
  );
};

export default Recipe;

import { connect } from "react-redux";
import * as ingredientsActions from "../actions/ingredient";
import { useEffect } from "react";

const IngredientsTagsLoaderComponent = ({ fetchIngredientsTags, children }) => {
  useEffect(() => {
    fetchIngredientsTags();
  }, [fetchIngredientsTags]);

  return children;
};

const IngredientsTagsLoader = connect((data) => {
  return {
    ingredientsTags: data.ingredientsTags,
  };
}, ingredientsActions)(IngredientsTagsLoaderComponent);

export default IngredientsTagsLoader;

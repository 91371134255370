import { connect } from "react-redux";
import * as ingredientActions from "../actions/ingredient";
import AddIngredientComponent from "../components/AddIngredient";

const AddIngredient = connect((data) => {
  return {
    ingredients: data.ingredients,
    ingredientsTags: data.ingredientsTags,
    ingredientImages: data.ingredientImages,
  };
}, ingredientActions)(AddIngredientComponent);

export default AddIngredient;

import { connect } from "react-redux";
import { addRecipe } from "../actions/recipe";
import { fetchRecipeImages } from "../actions/image";
import { addUnitiesTags } from "../actions/tag";
import AddRecipeComponent from "../components/AddRecipe";
import { withRouter } from "react-router-dom";

const AddRecipe = withRouter(
  connect(
    (state, ownProps) => {
      return {
        ingredients: state.ingredients,
        unitiesTags: state.unitiesTags,
        recipes: state.recipes,
        recipeTags: state.recipeTags,
      };
    },
    {
      addRecipe: addRecipe,
      fetchRecipeImages: fetchRecipeImages,
      addUnitiesTags: addUnitiesTags,
    }
  )(AddRecipeComponent)
);

export default AddRecipe;

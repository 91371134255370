import { connect } from "react-redux";
import * as ingredientActions from "../actions/ingredient";
import IngredientsListComponent from "../components/IngredientsList";
import { withRouter } from "react-router-dom";

const IngredientsList = withRouter(
  connect((data) => {
    return {
      ingredients: data.ingredients,
    };
  }, ingredientActions)(IngredientsListComponent)
);

export default IngredientsList;

import {Menu, Tooltip} from "antd";
import React from "react";
import {useHistory} from "react-router-dom";
import Icon from "@ant-design/icons";
import {emojis} from "./Emoji";
import {ReactComponent as cooking} from "../images/nav/cooking.svg";
import {ReactComponent as ingredients} from "../images/nav/ingredients.svg";
import {ReactComponent as recipes} from "../images/recipe/book.svg";

const NavMenu = () => {
  const history = useHistory();

  const handleOnMenuClick = (e) => {
    history.push("/" + (e.key === "home" ? "" : e.key));
  };

  return (
    <Menu mode="horizontal" onClick={handleOnMenuClick} disabledOverflow={true}>
      <Menu.Item
        key="home"
        icon={<Icon style={{ fontSize: "2em" }} component={recipes} />}
      >
        <Tooltip title={process.env.REACT_APP_VERSION}>
          Béarecettes{" "}
          {
            emojis[
              process.env.REACT_APP_VERSION.substring(
                process.env.REACT_APP_VERSION.lastIndexOf(".") + 1,
                process.env.REACT_APP_VERSION.length
              ) % emojis.length
            ]
          }
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        key="ajout-recette"
        icon={<Icon style={{ fontSize: "2em" }} component={cooking} />}
      >
        Nouvelle Recette
      </Menu.Item>
      <Menu.Item
        key="ingredients"
        icon={<Icon style={{ fontSize: "2em" }} component={ingredients} />}
      >
        Ingredients
      </Menu.Item>
    </Menu>
  );
};

export default NavMenu;

import { connect } from "react-redux";
import { deleteIngredient, updateIngredient } from "../actions/ingredient";
import { fetchIngredientImages, uploadIngredientFile } from "../actions/image";
import EditIngredientComponent from "../components/EditIngredient";
import { useParams, withRouter } from "react-router-dom";
import { deleteRecipe } from "../actions/recipe";

const IngredientDataPrepare = ({
  ingredients,
  ingredientImages,
  updateIngredient,
  uploadIngredientFile,
  deleteIngredient,
  fetchIngredientImages,
  history,
  recipes,
  recipeTags,
  deleteRecipe,
}) => {
  const { id } = useParams();
  const ingredient = ingredients.find((i) => i.id === id);

  if (ingredient !== undefined) {
    return (
      <EditIngredientComponent
        ingredient={ingredient}
        uploadIngredientFile={uploadIngredientFile}
        ingredients={ingredients}
        ingredientImages={ingredientImages}
        updateIngredient={updateIngredient}
        deleteIngredient={deleteIngredient}
        fetchIngredientImages={fetchIngredientImages}
        recipes={recipes.filter((r) => r.ingredients.find((i) => i.id === id))}
        recipeTags={recipeTags}
        history={history}
        deleteRecipe={deleteRecipe}
      />
    );
  } else {
    return <>Ingrédient inconnu</>;
  }
};

const EditIngredient = withRouter(
  connect(
    (state, ownProps) => {
      return {
        ingredients: state.ingredients,
        recipes: state.recipes,
        ingredientImages: state.ingredientImages,
        recipeTags: state.recipeTags,
      };
    },
    {
      deleteRecipe: deleteRecipe,
      updateIngredient: updateIngredient,
      uploadIngredientFile: uploadIngredientFile,
      deleteIngredient: deleteIngredient,
      fetchIngredientImages: fetchIngredientImages,
    }
  )(IngredientDataPrepare)
);

export default EditIngredient;

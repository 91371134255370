import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA14qFejlUgxI79aOYECqROq0XjJwrcPq0",
  authDomain: "recettes-a9acd.firebaseapp.com",
  databaseURL: "https://recettes-a9acd.firebaseio.com",
  projectId: "recettes-a9acd",
  storageBucket: "recettes-a9acd.appspot.com",
  messagingSenderId: "338318815541",
  appId: "1:338318815541:web:ef3b1d99ec16e81e99dc7c",
};

firebase.initializeApp(firebaseConfig);

const databaseRef = firebase.database().ref();
const storageRef = firebase.storage().ref();

export const TIMESTAMP = firebase.database.ServerValue.TIMESTAMP
export const unitiesTagsRef = databaseRef.child("unitiesTags");
export const recipeTagsRef = databaseRef.child("recipeTags");
export const recipesRef = databaseRef.child("recipes");
export const ingredientsRef = databaseRef.child("ingredients");
export const ingredientsTagsRef = databaseRef.child("ingredientsTags");
export const ingredientsStorageRef = storageRef.child("ingredients");
export const recipesStorageRef = storageRef.child("recipes");

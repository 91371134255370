import { connect } from "react-redux";
import { updateRecipe } from "../actions/recipe";
import { fetchRecipeImages } from "../actions/image";
import { addUnitiesTags } from "../actions/tag";
import EditRecipeComponent from "../components/EditRecipe";
import { useParams, withRouter } from "react-router-dom";

const RecipeDataPrepare = ({
  recipes,
  recipeTags,
  ingredients,
  ingredientImages,
  updateRecipe,
  fetchRecipeImages,
  unitiesTags,
  history,
}) => {
  const { id } = useParams();
  const recipe = recipes.find((i) => i.id === id);
  if (recipe !== undefined) {
    return (
      <EditRecipeComponent
        recipes={recipes}
        recipe={recipe}
        recipeTags={recipeTags}
        ingredients={ingredients}
        ingredientImages={ingredientImages}
        updateRecipe={updateRecipe}
        fetchRecipeImages={fetchRecipeImages}
        unitiesTags={unitiesTags}
        history={history}
      />
    );
  } else {
    return <>Recette inconnue"</>;
  }
};
const EditRecipe = withRouter(
  connect(
    (state, ownProps) => {
      return {
        ingredients: state.ingredients,
        ingredientImages: state.ingredientImages,
        unitiesTags: state.unitiesTags,
        recipes: state.recipes,
        recipeTags: state.recipeTags,
      };
    },
    {
      updateRecipe: updateRecipe,
      fetchRecipeImages: fetchRecipeImages,
      addUnitiesTags: addUnitiesTags,
    }
  )(RecipeDataPrepare)
);

export default EditRecipe;

import { connect } from "react-redux";
import * as imageActions from "../actions/image";
import { useEffect } from "react";

const ImageLoaderComponent = ({
  ingredientImages,
  fetchIngredientImages,
  children,
}) => {
  useEffect(() => {
    fetchIngredientImages();
  }, [fetchIngredientImages]);

  return Object.keys(ingredientImages).length > 0 ? children : null;
};

const IngredientImagesLoader = connect((data) => {
  return { ingredientImages: data.ingredientImages };
}, imageActions)(ImageLoaderComponent);

export default IngredientImagesLoader;

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AddIngredient from "./containers/AddIngredient";
import AddRecipe from "./containers/AddRecipe";
import RecipeTable from "./containers/RecipeTable";
import IngredientImagesLoader from "./containers/IngredientImagesLoader";
import RecipeImagesLoader from "./containers/RecipeImagesLoader";
import IngredientsTagsLoader from "./containers/IngredientsTagsLoader";
import UnitiesTagsLoader from "./containers/UnitiesTagsLoader";
import IngredientsLoader from "./containers/IngredientsLoader";
import RecipesLoader from "./containers/RecipesLoader";
import TagsEditor from "./containers/TagsEditor";
import Recipe from "./containers/Recipe";
import RecipeActions from "./containers/RecipeActions";
import RecipePDF from "./containers/RecipePDF";
import NavMenu from "./components/NavMenu";
import { Divider, Layout } from "antd";
import "antd/dist/antd.css";
import "./App.css";
import IngredientsList from "./containers/IngredientsList";
import EditIngredient from "./containers/EditIngredient";
import EditRecipe from "./containers/EditRecipe";
import RecipeTagsLoader from "./containers/RecipeTagsLoader";
import RecipeTagViewer from "./containers/RecipeTagViewer";
import RecipeList from "./containers/RecipeList";

const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Router>
      <IngredientImagesLoader>
        <RecipeTagsLoader>
          <RecipeImagesLoader>
            <UnitiesTagsLoader>
              <IngredientsTagsLoader>
                <IngredientsLoader>
                  <RecipesLoader>
                    <Layout style={{ backgroundColor: "#ffffff" }}>
                      <Header className="Nav-header">
                        <NavMenu />
                      </Header>
                      <Content>
                        <Switch>
                          <Route exact path={"/"}>
                            <RecipeTable />
                          </Route>
                          <Route path="/recette/:id">
                            <Recipe />
                          </Route>{" "}
                          <Route path="/recette-edit/:id">
                            <EditRecipe />
                          </Route>{" "}
                          <Route path="/recette-pdf/:id">
                            <RecipePDF />
                          </Route>
                          <Route exact path="/ingredient/edit/:id">
                            <EditIngredient />
                          </Route>{" "}
                          <Route exact path={"/recettes"}>
                            <RecipeList />
                          </Route>
                          <Route exact path={"/ajout-recette"}>
                            <AddRecipe />
                          </Route>
                          <Route exact path={"/ingredients"}>
                            <Divider>Ajouter un Ingredient</Divider>
                            <AddIngredient />
                            <IngredientsList />
                          </Route>
                          <Route exact path={"/cats"}>
                            <TagsEditor />
                          </Route>
                          <Route exact path={"/rcats"}>
                            <RecipeTagViewer />
                          </Route>
                        </Switch>
                      </Content>
                      <Switch>
                        <Route exact path={"/recette/:id"}>
                          <Footer className="Nav-footer">
                            <RecipeActions />
                          </Footer>
                        </Route>
                      </Switch>
                    </Layout>
                  </RecipesLoader>
                </IngredientsLoader>
              </IngredientsTagsLoader>
            </UnitiesTagsLoader>
          </RecipeImagesLoader>
        </RecipeTagsLoader>
      </IngredientImagesLoader>
    </Router>
  );
};

export default App;

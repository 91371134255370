import { connect } from "react-redux";
import { fetchRecipeTags } from "../actions/tag";
import { useEffect } from "react";

const CategoriesLoaderComponent = ({ fetchRecipeTags, children }) => {
  useEffect(() => {
    fetchRecipeTags();
  }, [fetchRecipeTags]);

  return children;
};

const RecipeTagsLoader = connect(
  (data) => {
    return { recipeTags: data.recipeTags };
  },
  { fetchRecipeTags }
)(CategoriesLoaderComponent);

export default RecipeTagsLoader;

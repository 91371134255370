import React, { useCallback, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Modal } from "antd";

const CropImageModal = ({ value = {}, visible, onCancel, onOk }) => {
  const [sourceUrl, setSourceUrl] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [crop, setCrop] = useState();
  const [previewUrl, setPreviewUrl] = useState(
    value.dataUrl ? value.dataUrl : value.url
  );

  const onLocalOk = () => {
    onOk(previewUrl);
  };

  const onLocalCancel = () => {
    onCancel();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSourceUrl(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    setImgRef(img);
    const aspect = 16 / 9;
    const width =
      img.width / aspect < img.height * aspect
        ? 100
        : ((img.height * aspect) / img.width) * 100;
    const height =
      img.width / aspect > img.height * aspect
        ? 100
        : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;

    setCrop({
      unit: "%",
      width,
      height,
      x,
      y,
      aspect,
    });

    return false; // Return false if you set crop state in here.
  }, []);

  const makeClientCrop = async (crop, percentCrop) => {
    if (imgRef && crop.width && crop.height) {
      return createCropPreview(imgRef, crop);
    }
  };

  const createCropPreview = async (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    setPreviewUrl(canvas.toDataURL());
  };

  return (
    <Modal
      title="Selectionner une photo"
      visible={visible}
      onOk={onLocalOk}
      onCancel={onLocalCancel}
      style={{ textAlign: "center" }}
    >
      <div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <ReactCrop
        src={sourceUrl}
        onImageLoaded={onLoad}
        crop={crop}
        ruleOfThirds
        onChange={(crop, percentCrop) => setCrop(percentCrop)}
        onComplete={makeClientCrop}
      />
      {previewUrl && <img alt="Crop preview" src={previewUrl} />}
    </Modal>
  );
};

export default CropImageModal;

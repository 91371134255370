import { connect } from "react-redux";
import * as recipeActions from "../actions/recipe";
import { useEffect } from "react";

const RecipesLoaderComponent = ({
  recipes,
  recipeImages,
  ingredients,
  fetchRecipes,
  children,
}) => {
  useEffect(() => {
    if (Object.keys(recipeImages).length > 0) {
      fetchRecipes(recipeImages, ingredients);
    }
  }, [fetchRecipes, recipeImages, ingredients]);

  return recipes !== undefined && recipes.length > 0 ? children : null;
};

const RecipesLoader = connect((data) => {
  return {
    recipes: data.recipes,
    recipeImages: data.recipeImages,
    ingredients: data.ingredients,
  };
}, recipeActions)(RecipesLoaderComponent);

export default RecipesLoader;

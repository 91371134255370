import React, {useEffect, useState} from "react";
import { Avatar, Card, Input, Tooltip } from "antd";

const { Meta } = Card;
const { Search } = Input;

const IngredientsList = ({ ingredients, history }) => {
    useEffect(() => {
        setFilteredData(ingredients);
    }, [ingredients]);

    const [filteredData, setFilteredData] = useState(ingredients);

    const handleSearch = (e) => {
        const value = e.target.value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
        const searchResult = ingredients.filter(
            (d) =>
                d.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .indexOf(value) !== -1
        );

        setFilteredData(searchResult);
    };

  return (
    <>
      <Search
        placeholder="Rechercher un ingredient"
        onChange={handleSearch}
        size={"large"}
        enterButton
      />
      <Card>
        {filteredData.map((item) => (
          <Card.Grid
            key={item.id}
            hoverable
            onClick={() => {
              history.push("/ingredient/edit/" + item.id);
            }}
            style={{
              width: "100px",
              textAlign: "center",
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 0,
              paddingRight: 0,
              cursor: "pointer",
            }}
          >
            <Card
              bordered={false}
              cover={
                <Avatar
                  shape="square"
                  // size="small"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "32px",
                  }}
                  src={item.url}
                />
              }
            >
              <Meta title={<Tooltip title={item.name}>{item.name}</Tooltip>} />
            </Card>
          </Card.Grid>
        ))}
      </Card>
    </>
  );
};

export default IngredientsList;

import React from "react";
import { AutoComplete, Button, Form, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
};

const normalizedName = (name) => {
  const clean = name.trim().replace(/\s+/g, " ").toLowerCase();
  return (
    clean.charAt(0).toUpperCase() +
    clean.slice(1) +
    (name.endsWith(" ") ? " " : "")
  );
};

const TagsEditor = ({ unitiesTags, addUnitiesTags }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    addUnitiesTags(unitiesTags, [values]);
    form.resetFields();
    message.success('Le tag "' + values.name + '" a été ajouté');
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onNameChange = (value) => {
    form.setFieldsValue({ name: normalizedName(value) });
  };

  const nameIsValid = () => {
    // const existing = unitiesTags.reduce((acc, i) => {
    //   acc.push(
    //     i.name
    //       .normalize("NFD")
    //       .replace(/[\u0300-\u036f]/g, "")
    //       .toLowerCase()
    //   );
    //   return acc;
    // }, []);
    // return (
    //   existing.indexOf(
    //     name
    //       .trim()
    //       .normalize("NFD")
    //       .replace(/[\u0300-\u036f]/g, "")
    //       .toLowerCase()
    //   ) < 0
    // );
    return true;
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="add-ingredient"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Catégorie" name="category">
          <Input />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="name"
          rules={[
            { required: true, message: "Le nom est requis!" },
            () => ({
              validator(rule, value) {
                if (value === undefined || nameIsValid(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("L'ingrédient existe déja!");
              },
            }),
          ]}
        >
          <AutoComplete
            options={Object.keys(unitiesTags).map((id) => ({
              value: unitiesTags[id].name,
            }))}
            filterOption={(inputValue, option) =>
              option.value
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
                .indexOf(
                  inputValue
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                ) !== -1
            }
            onChange={onNameChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            <PlusCircleOutlined />
            Ajouter
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TagsEditor;

import { connect } from "react-redux";
import * as ingredientsActions from "../actions/ingredient";
import { useEffect } from "react";

const IngredientsLoaderComponent = ({
  ingredients,
  ingredientImages,
  fetchIngredients,
  children,
}) => {
  useEffect(() => {
    if (ingredientImages !== undefined) {
      fetchIngredients(ingredientImages);
    }
  }, [fetchIngredients, ingredientImages]);

  return ingredients !== undefined && ingredients.length > 0 ? children : null;
};

const IngredientsLoader = connect((data) => {
  return {
    ingredientImages: data.ingredientImages,
    ingredients: data.ingredients,
  };
}, ingredientsActions)(IngredientsLoaderComponent);

export default IngredientsLoader;
